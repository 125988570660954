import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { AuthContext } from "../../../context/AuthProvider";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import RegisterForm from "../createUser/registerForm";
import Tooltip from "@material-ui/core/Tooltip";
import DataTable from "../../../views/private/userManagement/usersManagements";

//multilanguage
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  headerRegister: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px",
  },
  logo: {
    height: "80px",
  },
  icons: {
    display: "flex",
    position: "absolute",
    color: "#929292",
    right: "2px",
  },
}));

export default function ResponsiveDialog({ refetch }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { permissionsRoleUser, openCreateUserModal, setOpenCreateUserModal } =
    useContext(AuthContext);

  const handleClose = () => {
    setOpenCreateUserModal(false);
  };

  const modals = (state) => {
    setOpenCreateUserModal(state);
  };

  return (
    <React.Fragment>
      <Dialog
        // fullScreen={fullScreen}
        open={openCreateUserModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "auto", height: "auto", borderRadius: "8px" },
        }}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "#2EB142" }}>
          <Toolbar>
            <Typography
              sx={{ flex: "2px", textAlign: "center" }}
              variant="h6"
              component="div"
            >
              {t(
                "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.TITLE_MODAL"
              )}
            </Typography>
            <IconButton
              color="inherit"
              onClick={handleClose}
              sx={{
                display: "flex",
                position: "absolute",
              }}
              edge="end"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />

        <DialogContent>
          <RegisterForm modal={modals} refetch={refetch} theme={theme} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
