export function handleChangeLevel(
  limite,
  incrementar,
  level,
  setReferenceLevel,
  intervalRef,
  setLevel,
  referenceLevel
) {
  setLevel(referenceLevel);
  clearInterval(intervalRef?.current);
  intervalRef.current = setInterval(() => {
    setReferenceLevel((prevLevel) => {
      const step = 10;
      let newValue = incrementar ? prevLevel + step : prevLevel - step;
      newValue = incrementar
        ? Math.min(newValue, limite)
        : Math.max(newValue, 0);
      // Si el nuevo valor alcanza el límite, actualiza directamente con setLevel y limpia el intervalo
      if (newValue === limite || (!incrementar && newValue <= 0)) {
        clearInterval(intervalRef?.current);
        setLevel(newValue); // Asegura que el nivel se actualice al valor de newValue cuando se detiene
      }
      return newValue;
    });
  }, 500);
}

export function initialValue(typeGateway, data) {
  if (typeGateway === "horus_700") {
    const found = data?.curtains?.variables?.items?.find(
      (element) => element.name === "dimmer"
    );
    return parseInt(found.status);
  } else if (typeGateway === "horus_g150") {
    return parseInt(data?.item?.variables?.LoadLevelStatus);
  }
}

export function stopChange(intervalRef) {
  clearInterval(intervalRef.current);
}

export function handleSliderChange(
  newValue,
  setLevel,
  intervalRef,
  setReferenceLevel
) {
  stopChange(intervalRef);
  setLevel(newValue);
  setReferenceLevel(newValue);
}

export const handlerOnlineCurtain = (
  curtainStatus700,
  controllerStatus,
  curtainStatus150,
  typeGateway
) => {
  if (typeGateway === "horus_700") {
    if (curtainStatus700 && controllerStatus) {
      return true;
    } else {
      return false;
    }
  } else if (typeGateway === "horus_g150") {
    if (curtainStatus150 === "0" || curtainStatus150 === undefined) {
      return true;
    } else {
      return false;
    }
  }
};

export const sendWebSocketData = (
  dataSentG150,
  dataSentH700,
  client,
  typeGateway,
  sendMessage,
  setEnableSlider
) => {
  try {
    if (typeGateway === "horus_g150") {
      if (client && client.readyState === WebSocket.OPEN) {
        client.send(JSON.stringify(dataSentG150));
        return { success: true, message: "Datos enviados exitosamente." };
      } else {
        console.error(
          "El socket no está abierto o no está listo para enviar datos."
        );
        return {
          success: false,
          message:
            "El socket no está abierto o no está listo para enviar datos.",
        };
      }
    } else if (typeGateway === "horus_700") {
      sendMessage({
        id: String(dataSentH700?.id),
        eventType: dataSentH700?.eventType,
        value: dataSentH700?.value,
        channel: dataSentH700?.serial,
        serial: dataSentH700?.serial,
      });
      if (dataSentH700?.eventType === "dimmer_stop") {
        setEnableSlider(true);
      }
    }
  } catch (error) {
    console.error("Error al enviar datos:", error.message);
    return { success: false, message: "Error al enviar datos." };
  }
};
