import React, { useState, useContext } from "react";

//Queries
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_CONTROLLERS,
  UPDATE_ROOM,
} from "../../../../controllers/roomControllers";

//Material UI components
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

//Material UI components & Styles
import { createTheme, ThemeProvider } from "@mui/material/styles";

//multilanguage
import { useTranslation } from "react-i18next";

const EditRoomModal = (props) => {
  const { areas, projectId, openEditRoomModal, setEditRoomModal, refetchArea } =
    props;
  const { t } = useTranslation();
  const customGreen = "#acf6ac";
  const theme = createTheme({
    palette: {
      success: {
        main: customGreen,
      },
    },
  });
  const [updateRoom] = useMutation(UPDATE_ROOM);
  const { data, loading, refetch } = useQuery(GET_ALL_CONTROLLERS, {
    variables: {
      projectId: projectId,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const controllers = data?.getAllControllers;
  const [areaId, setAreaId] = useState("");
  const [areaName, setAreaName] = useState("");
  const [controllerId, setControllerId] = useState("");
  const [controllerSerial, setConrollerSerial] = useState("");
  const [typeGateway, setTypeGateway] = useState("");
  const selectedAreaTypeGateway = areas?.find(
    (area) => area?.id === areaId || area?.NewRoomId === areaId
  )?.Controller?.typeGateway;
  const selectedAreaId = areas?.find(
    (area) => area?.id === areaId || area?.NewRoomId === areaId
  )?.id;

  const filteredControllers = controllers?.filter(
    (controller) =>
      controller?.typeGateway === selectedAreaTypeGateway ||
      (controller?.typeGateway === "virtual" &&
        selectedAreaTypeGateway === "horus_700") ||
      (controller?.typeGateway === "horus_700" &&
        selectedAreaTypeGateway === "virtual")
  );

  const handlerCloseEditRoomModal = () => {
    setEditRoomModal(false);
    setAreaId("");
    setAreaName("");
    setControllerId("");
    setConrollerSerial("");
    setTypeGateway("");
  };

  const handlerPickArea = (id) => {
    const area = areas?.find(
      (area) => area?.id === id || area?.NewRoomId === id
    );
    setAreaId(id);
    setAreaName(area?.name);
    setControllerId(area?.Controller?.id);
    setConrollerSerial(area?.Controller?.serial);
    setTypeGateway(area?.Controller?.typeGateway);
  };

  const handlerEditAreaName = (value) => {
    setAreaName(value);
  };

  const handlerPickController = (id) => {
    const controller = controllers?.filter(
      (controller) =>
        controller?.serial === controllerSerial &&
        controller?.typeGateway === selectedAreaTypeGateway
    );
    setControllerId(id);
    setTypeGateway(controller[0]?.typeGateway);
  };

  async function handlerUpdateRoom() {
    if (areaId && areaName && controllerId) {
      try {
        updateRoom({
          variables: {
            updateRoomId: selectedAreaId,
            typeGateway: typeGateway,
            data: {
              name: areaName,
              alias: areaName,
              ProjectId: projectId,
              ControllerId: controllerId,
            },
          },
        });
        setTimeout(() => {
          refetchArea();
          handlerCloseEditRoomModal();
        }, 2000);
      } catch (err) {
        console.error("Excepción:", err);
      }
    }
  }

  return (
    <>
      <Dialog
        open={openEditRoomModal}
        PaperProps={{
          sx: {
            display: "flex",
            borderRadius: "8px",
            width: "35%",
            height: "auto",
            [theme.breakpoints.down("md")]: {
              width: "45%",
            },
            [theme.breakpoints.down("sm")]: {
              width: "90%",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#2EB142",
            color: "#FFFFFF",
            lineHeight: "2.6",
            padding: "5px 5px 5px 40px",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "auto",
              fontWeight: "bold",
            }}
          >
            {t("VIEW_AREAS.COMPONENT_EDIT_ROOM_MODAL.TITLE")}
          </span>
          <IconButton onClick={handlerCloseEditRoomModal}>
            <CloseIcon sx={{ color: "#ffff" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "25px",
            padding: "20px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              width: "90%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "18px",
              marginTop: "20px",
            }}
          >
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">{"Area"}</InputLabel>
              <Select
                sx={{ borderRadius: "8px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={areaId}
                label={"Area"}
                onChange={(e) => handlerPickArea(e.target.value)}
              >
                {areas.map((value) => (
                  <MenuItem
                    key={value.id}
                    value={value.NewRoomId ? value.NewRoomId : value.id}
                  >
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="outlined-basic"
              label={t("VIEW_AREAS.COMPONENT_EDIT_ROOM_MODAL.AREA_NAME")}
              variant="outlined"
              size="small"
              InputProps={{
                sx: { borderRadius: "8px", width: "100%" },
              }}
              disabled={!areaId}
              onChange={(e) => handlerEditAreaName(e.target.value)}
              value={areaName}
              required={true}
              color={areaName ? "primary" : "error"}
            />

            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label" disabled={!areaName}>
                {t("VIEW_AREAS.COMPONENT_EDIT_ROOM_MODAL.CONTROLLER")}
              </InputLabel>
              <Select
                sx={{ borderRadius: "8px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={!areaId}
                value={controllerId}
                label={t("VIEW_AREAS.COMPONENT_EDIT_ROOM_MODAL.CONTROLLER")}
                onChange={(e) => handlerPickController(e.target.value)}
              >
                {filteredControllers?.map((value) => (
                  <MenuItem key={value?.id} value={value?.id}>
                    {value?.serial}
                    {value?.name !== value?.serial ? ` - ${value?.name}` : ""}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <ThemeProvider theme={theme}>
              <Button
                sx={{
                  width: "100%",
                  padding: "7px",
                  color: "#000",
                  borderRadius: "8px",
                  lineHeight: "18px",
                }}
                variant="contained"
                color="success"
                onClick={() => handlerUpdateRoom()}
                disabled={areaId && areaName && controllerId ? false : true}
              >
                {t("VIEW_AREAS.COMPONENT_EDIT_ROOM_MODAL.ACCEPT_BUTTON")}
              </Button>
            </ThemeProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditRoomModal;
