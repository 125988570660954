import React from "react";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { DateRangeContext } from "./context/ReactDateRangeContext";

import PropTypes from "prop-types";

//Helper functions
import {
  handleErrorMessageDatePicker,
  handlerFormatDate,
  handlerFormatCalendarDay,
  handlerPreviousDay,
  handlerNextDay,
  allowNextDayButton,
} from "./helper";

//Components
import Calendars from "./components/Calendars";

//Multilanguage
import { useTranslation } from "react-i18next";

// NOTA IMPORTANTE
// Este componente funciona cuando todas sus props son definidas (selectTypeRange:      string, closingTime: number, closingType: boolean) y tienen el tipo de dato correcto.

const ButtonDays = (props) => {
  const { buttonText, buttonIcon, selectTypeRange, onClick } = props;
  const { t } = useTranslation();
  return (
    <>
      {selectTypeRange === "d" ? (
        <button className="DateRangeButtonContainer" onClick={onClick}>
          {buttonText === t("VIEW_DATE_PICKER.BTN_BACK_DAY") ? (
            <>
              <i className={buttonIcon}></i>
              <span className="DateRangeButtonContent-Text">{buttonText}</span>
            </>
          ) : (
            <>
              <span className="DateRangeButtonContent-Text">{buttonText}</span>
              <i className={buttonIcon}></i>
            </>
          )}
        </button>
      ) : null}
    </>
  );
};

const CalendarInput = (props) => {
  const { date, inputIcon, onClick } = props;
  return (
    <>
      <div className="DateRangeInputContainer" onClick={onClick}>
        <i className={inputIcon}></i>
        {date}
      </div>
    </>
  );
};

export const WrapperReactDateRange = (props) => {
  const { selectTypeRange, closingTime, closingType } = props;
  const { t } = useTranslation();
  const {
    calendarDay,
    setCalendarDay,
    acceptButton,
    setAcceptButton,
    reactDateRange,
    setReactDateRange,
  } = useContext(DateRangeContext);

  const {
    globalReactDateRange,
    setGlobalReactDateRange,
    globalCalendarDay,
    setGlobalCalendarDay,
  } = useContext(AuthContext);

  const [dropDownCalendar, setDropDownCalendar] = useState(false);
  handleErrorMessageDatePicker(selectTypeRange, closingTime, closingType);

  //Hace un set al la vairable global globalCalendarDay cuando presional el boton aceptar
  useEffect(() => {
    if (calendarDay && acceptButton) {
      setGlobalCalendarDay(calendarDay);
      setAcceptButton(false);
    }
  }, [calendarDay, acceptButton]);

  //Hace un set a la vairable global globalReactDateRange cuando presional el boton aceptar
  useEffect(() => {
    if (reactDateRange && acceptButton) {
      setGlobalReactDateRange([
        {
          startDate: reactDateRange[0]?.startDate,
          endDate: reactDateRange[0]?.endDate,
          key: "selection",
        },
      ]);
      setAcceptButton(false);
    }
  }, [reactDateRange, acceptButton]);

  return (
    <>
      <div className="DateRangeAndCalendarContainer">
        <div className="ReactDateRangeContainer">
          <div className="ButtonsAndCalendar">
            <ButtonDays
              buttonText={t("VIEW_DATE_PICKER.BTN_BACK_DAY")}
              buttonIcon="fas fa-backward"
              selectTypeRange={selectTypeRange}
              onClick={() =>
                handlerPreviousDay(globalCalendarDay, setGlobalCalendarDay)
              }
            />
            <div className="DescriptionAndCalendar">
              <CalendarInput
                inputIcon="far fa-calendar-alt"
                date={
                  selectTypeRange === "d"
                    ? handlerFormatCalendarDay(globalCalendarDay)
                    : handlerFormatDate(globalReactDateRange)
                }
                onClick={() => setDropDownCalendar(!dropDownCalendar)}
              />
              <span className="Description">
                {selectTypeRange === "w" ? (
                  <>{t("VIEW_DATE_PICKER.MAX_DAYS_7")}</>
                ) : null}
                {selectTypeRange === "m" ? (
                  <>{t("VIEW_DATE_PICKER.MAX_DAYS")}</>
                ) : null}
              </span>
            </div>

            {allowNextDayButton(globalCalendarDay, closingTime) ? (
              <ButtonDays
                buttonText={t("VIEW_DATE_PICKER.BTN_NEXT_DAY")}
                buttonIcon="fas fa-forward"
                selectTypeRange={selectTypeRange}
                onClick={() =>
                  handlerNextDay(globalCalendarDay, setGlobalCalendarDay)
                }
              />
            ) : null}
          </div>
        </div>

        {dropDownCalendar && (
          <div className="Calendar">
            <Calendars
              selectTypeRange={selectTypeRange}
              closingTime={closingTime}
              closingType={closingType}
              setDropDownCalendar={setDropDownCalendar}
              calendarDay={calendarDay}
              setCalendarDay={setCalendarDay}
              setAcceptButton={setAcceptButton}
              reactDateRange={reactDateRange}
              setReactDateRange={setReactDateRange}
            />
          </div>
        )}
      </div>
    </>
  );
};

WrapperReactDateRange.propTypes = {
  selectTypeRange: PropTypes.string.isRequired,
  closingTime: PropTypes.number.isRequired,
  closingType: PropTypes.bool.isRequired,
};
