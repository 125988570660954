import React, { useState, useContext, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VisibilityTwo from "@material-ui/icons/Visibility";
import VisibilityOffTwo from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Alert from "@mui/material/Alert";
import { AlertTitle } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Fragment } from "react";
import { AuthContext } from "../../../context/AuthProvider";
import Snackbar from "@mui/material/Snackbar";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { partnerCountries } from "../../../utils/dataCountries.json";
import { encryptData } from "../../../utils/encryptDecrypt";

//query & mutation
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_USER_ROLE,
  CREATE_USER,
} from "../../../controllers/usersControllers";

//components
import Loading from "../../loading/Loading";
import { Box } from "@material-ui/core";

//multilanguage
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    padding: "15px",
  },
  forms: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  tittle: {
    padding: "0px",
    marginLeft: "10px",
    marginTop: "-4rem",
    marginBottom: "10px",
  },
  input: {
    marginLeft: "10px",
    marginTop: "10px",
  },
  tittleTwo: {
    marginBottom: "0px",
    marginTop: "0px",
  },
  tittleThree: {
    marginTop: "16px",
    marginLeft: "10px",
    marginBottom: "15px",
  },
  rolesProjectsContainer: {
    display: "grid",
    gridTemplateColumns: "50% 50%",

    [theme.breakpoints.down("xs")]: { gridTemplateColumns: "100%" },
  },
  inputPassword: {
    width: "416px",
    marginLeft: "7.7px",
    marginTop: "5px",
  },
  inputPasswordTwo: {
    width: "23.2ch",
    marginLeft: "15px",
    marginTop: "5px",
  },

  butreg: {
    width: "-webkit-fill-available",
    backgroundColor: "#2EB142",
    marginTop: "10px",
    marginLeft: "7px",
    marginBottom: "6px",
    height: "35px",
    color: "white",
    borderRadius: "7px",
    "&:hover": {
      backgroundColor: "#336535",
    },
  },
  formsUserLogin: {
    display: "flex",
    width: "100%",
  },
  error: {
    backgroundColor: "#FC2121",
    fontSize: "16px",
    borderRadius: "5px",
    zIndex: "2",
    position: "relative",
    with: "100px",
  },
  userlog: {
    width: "100ch",
    userlog: {
      width: "100ch",
    },
    formsUserLogin: {
      display: "flex",
      width: "100px",
    },
  },
}));

export const RegisterForm = ({ modal, theme, refetch }) => {
  const { t } = useTranslation();
  const [register] = useMutation(CREATE_USER);
  const [datauser, setDatauser] = React.useState({
    userName: "",
    lastName: "",
    idNumber: "",
    cellPhone: "",
    countryCode: "",
    email: "",
    isValidEmail: false,
    isValidPassword: false,
    userLogin: "",
    allRoles: "",
    password: "",
    passwordTwo: "",
    confirmPassword: false,
    ProjectId: [],
    projectIdLength: true,
  });

  // useEffect(() => {
  //   setNewDataCountries(partnerCountries);
  // }, [partnerCountries]);

  const [nameProject, setProjectName] = React.useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [noFilledTextfield, setNoFilledTextfield] = useState("error");
  const [colorTextFields, setColorTextFields] = useState("success");
  const [showAlertTitle, setShowAlertTitle] = useState(false);
  // const [newDataCountries, setNewDataCountries] = useState([]);
  const { np } = useContext(AuthContext);

  const { data, loading, error } = useQuery(GET_USER_ROLE, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: parseInt(localStorage.getItem("np")),
    },
  });

  const keyPassword = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY;
  //activar válidación de idNumber, countryCode y cellPhone cuando sea el momento oportuno, no eliminar.

  const classes = useStyles();
  const isDisabledSubmit =
    datauser.userName.length < 3 ||
    datauser.lastName.length < 3 ||
    // datauser.idNumber === "" ||
    // datauser.cellPhone === "" ||
    // datauser.cellPhone.length < 10 ||
    // datauser.cellPhone.length > 11 ||
    // datauser.countryCode === "" ||
    datauser.email === "" ||
    datauser.userLogin.length < 5 ||
    (datauser.email && !datauser.isValidEmail) ||
    !datauser.allRoles ||
    datauser.projectIdLength === true ||
    datauser.password.length < 8 ||
    !datauser.isValidPassword ||
    datauser.passwordTwo.length < 8 ||
    datauser.passwordTwo !== datauser.password;

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [valuesTwo, setValuesTwo] = React.useState({
    passwordTwo: "",
    showPasswordTwo: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPasswordTwo = () => {
    setValuesTwo({ ...valuesTwo, showPasswordTwo: !valuesTwo.showPasswordTwo });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPasswordTwo = (event) => {
    event.preventDefault();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
    setShowAlertTitle(false);
  };

  if (error) {
    return (
      <div>
        <lottie-player
          style={{
            width: "70%",
            marginLeft: "30%",
            marginRight: "auto",
          }}
          className="_logo"
          src="https://assets1.lottiefiles.com/private_files/lf30_tonsVH.json"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
      </div>
    );
  }

  const handleReg = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const regex = /[^a-zA-ZÑñ ]+/g;
    const filteredValue = value.replace(regex, "");
    if (filteredValue !== value) {
    } else {
      setDatauser({
        ...datauser,
        [name]: value,
      });
    }
  };

  const handleRegSelect = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setDatauser({
      ...datauser,
      [name]: value,
    });
  };

  const handleselectCountry = (code) => {
    const aux = code.replace(/\D/g, "");
    setDatauser({
      ...datauser,
      countryCode: aux,
    });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const regex = /[^0-9]/g;
    const numericValue = value.replace(regex, "");
    if (numericValue !== value) {
    } else {
      setDatauser({
        ...datauser,
        [name]: value,
      });
    }
  };

  const validateEmail = (email) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    const isValidEmail = validateEmail(email);
    setDatauser({
      ...datauser,
      email: email,
      isValidEmail: isValidEmail,
    });
  };

  const validatePassword = (event) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.\s#^+\-])[A-Za-z\d@$!%*?&.\s#^+\-]{8,}$/;
    const password = event.target.value;
    setDatauser({
      ...datauser,
      password: password,
      isValidPassword: regex.test(password),
    });
  };

  const validatePassword2 = (event) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.\s#^+\-])[A-Za-z\d@$!%*?&.\s#^+\-]{8,}$/;
    const password = event.target.value;
    setDatauser({
      ...datauser,
      passwordTwo: password,
      isValidPassword: regex.test(password),
    });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setProjectName(typeof value === "string" ? value.split(",") : value);
    setDatauser({
      ...datauser,
      ProjectId: nameProject,
    });
    if (value.length === 0) {
      setDatauser({
        ...datauser,
        projectIdLength: true,
      });
    } else {
      if (value.length > 0) {
        setDatauser({
          ...datauser,
          projectIdLength: false,
        });
      }
    }
  };

  const handleCloseModal = () => {
    setTimeout(() => {
      modal(false);
    }, 2000);
  };

  // const handleClick2 = () => {
  //   setOpenMessage2(true);
  // };

  //activar válidación de idNumber, countryCode y cellPhone cuando sea el momento oportuno, no eliminar.

  const userRegister = async () => {
    if (
      datauser.userName === "" ||
      datauser.userName.length < 3 ||
      datauser.lastName === "" ||
      datauser.lastName.length < 3 ||
      // datauser.idNumber === "" ||
      // datauser.idNumber.length < 5 ||
      // datauser.idNumber.length > 11 ||
      // datauser.countryCode === "" ||
      // datauser.cellPhone === "" ||
      // datauser.cellPhone.length < 10 ||
      // datauser.cellPhone.length > 11 ||
      datauser.userLogin === "" ||
      datauser.userLogin.length < 5 ||
      datauser.email === "" ||
      (datauser.email && !datauser.isValidEmail) ||
      !datauser.allRoles ||
      datauser.projectIdLength === true ||
      datauser.password === "" ||
      datauser.password.length < 8 ||
      !datauser.isValidPassword ||
      datauser.passwordTwo === "" ||
      datauser.passwordTwo.length < 8 ||
      datauser.passwordTwo !== datauser.password
    ) {
      setShowAlertTitle(true);
    } else {
      try {
        register({
          variables: {
            name: datauser.userName,
            last_name: datauser.lastName,
            identification_number: await encryptData(
              datauser.idNumber,
              keyPassword
            ),
            email: await encryptData(datauser.email, keyPassword),
            cellPhone: await encryptData(datauser.cellPhone, keyPassword),
            countryCode: datauser.countryCode,
            user: datauser.userLogin,
            password: await encryptData(datauser.password, keyPassword),
            passwordTwo: await encryptData(datauser.password, keyPassword),
            ProjectId: nameProject,
            RoleId: parseInt(datauser.allRoles),
          },
        })
          .then((response) => {
            if (response.data && response.data.error === "Validation error") {
              console.log("Error de validación", response.data.message);
            } else {
              setOpenMessage(true);
              refetch();
              handleCloseModal();
            }
          })
          .catch((error) => {
            console.log("Error", error);
            setShowAlertTitle(true);
          });
      } catch (err) {
        console.error("Excepción:", err);
      }
    }
  };

  return (
    <Fragment>
      <form className={classes.root} noValidate autoComplete="off">
        <div className={classes.forms}>
          <TextField
            InputProps={{
              sx: {
                borderRadius: "8px",
              },
            }}
            sx={{
              width: "226.17px",
              [theme?.breakpoints?.down("xs")]: { width: "auto" },
            }}
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.NAME"
            )}
            autoComplete="Nombre"
            type="text"
            id="userName"
            name="userName"
            variant="outlined"
            size="small"
            required
            value={datauser.userName}
            onChange={handleReg}
            color={
              datauser.userName === "" || datauser.userName.length < 3
                ? noFilledTextfield
                : colorTextFields
            }
          />
          <TextField
            InputProps={{ sx: { borderRadius: "8px" } }}
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.LAST_NAME"
            )}
            id="lastName"
            name="lastName"
            variant="outlined"
            size="small"
            required
            value={datauser.lastName}
            onChange={handleReg}
            color={
              datauser.lastName === "" || datauser.lastName.length < 3
                ? noFilledTextfield
                : colorTextFields
            }
          />
        </div>
        <div className={classes.forms}>
          <TextField
            InputProps={{ sx: { borderRadius: "8px" } }}
            sx={{
              width: "226.17px",
              [theme?.breakpoints?.down("xs")]: { width: "auto" },
            }}
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.IDENTIFICATON_NUMBER"
            )}
            id="idNumber"
            name="idNumber"
            variant="outlined"
            size="small"
            value={datauser.idNumber}
            onChange={handleInputChange}
            // color={
            //   datauser.idNumber === "" ||
            //   datauser.idNumber.length < 5 ||
            //   datauser.idNumber.length > 11
            //     ? noFilledTextfield
            //     : colorTextFields
            // }
          />
          <TextField
            InputProps={{ sx: { borderRadius: "8px" } }}
            className={classes.textField}
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.EMAIL"
            )}
            type="email"
            id="email"
            name="email"
            variant="outlined"
            size="small"
            required
            value={datauser.email}
            onChange={handleEmailChange}
            color={
              datauser.email === "" ||
              (datauser.email && !datauser.isValidEmail)
                ? noFilledTextfield
                : colorTextFields
            }
            helperText={
              datauser.email === "" ||
              (datauser.email && !datauser.isValidEmail)
                ? t(
                    "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.EMAIL_HELPER_TEXT"
                  )
                : ""
            }
          />
        </div>
        <div className={classes.forms}>
          <Autocomplete
            id="country-select-demo"
            size="small"
            options={partnerCountries}
            onChange={(e) => handleselectCountry(e.target.textContent)}
            autoHighlight
            getOptionLabel={(option) => `+${option.phone} (${option.name})`}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.name} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  width: "226.17px",
                  [theme?.breakpoints?.down("xs")]: {
                    width: "-webkit-fill-available",
                  },
                }}
                {...params}
                label={t(
                  "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.COUNTRY"
                )}
                // color={
                //   datauser.countryCode === ""
                //     ? noFilledTextfield
                //     : colorTextFields
                // }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              >
                {partnerCountries.map((countryInitial) => (
                  <MenuItem
                    key={countryInitial.code}
                    value={countryInitial.code}
                  >
                    {countryInitial.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <TextField
            InputProps={{ sx: { borderRadius: "8px" } }}
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.CELLPHONE"
            )}
            id="cellPhone"
            name="cellPhone"
            variant="outlined"
            size="small"
            inputProps="object.number"
            value={datauser.cellPhone}
            onChange={handleInputChange}
            // color={
            //   datauser.cellPhone === "" ||
            //   datauser.cellPhone.length < 10 ||
            //   datauser.cellPhone.length > 11
            //     ? noFilledTextfield
            //     : colorTextFields
            // }
          />
        </div>

        <div>
          <h4 className={classes.tittleThree}>
            {t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.DATA_ACCESS_LABEL"
            )}
          </h4>
        </div>
        <div className={classes.forms}>
          <TextField
            InputProps={{ sx: { borderRadius: "8px" } }}
            sx={{
              width: 465,
              [theme?.breakpoints?.down("xs")]: { width: "auto" },
            }}
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.USER"
            )}
            id="userLogin"
            name="userLogin"
            placeholder="Nombre de Usuario"
            variant="outlined"
            size="small"
            value={datauser.userLogin}
            className={classes.userlog}
            onChange={handleReg}
            color={
              datauser.userLogin === "" || datauser.userLogin.length < 5
                ? noFilledTextfield
                : colorTextFields
            }
            helperText={
              datauser.userLogin === "" || datauser.userLogin.length < 5
                ? t(
                    "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.USER_HELPER_TEXT"
                  )
                : ""
            }
            required
          />
        </div>
        <div className={classes.rolesProjectsContainer}>
          <TextField
            InputProps={{ sx: { borderRadius: "8px" } }}
            id="rol"
            name="allRoles"
            select={<multiple />}
            size="small"
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.ROLE"
            )}
            variant="outlined"
            color={!datauser.allRoles ? noFilledTextfield : colorTextFields}
            onChange={(e) => handleRegSelect(e)}
          >
            {data?.getAllRoles?.map((Role) => (
              <MenuItem key={Role.id} value={Role.id}>
                {Role.name}
              </MenuItem>
            ))}
          </TextField>

          <FormControl sx={{ marginTop: "8px", marginLeft: "7px" }}>
            <InputLabel
              sx={{
                marginTop: -1,
              }}
              id="ProjectId"
            >
              {t(
                "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.PROJECTS"
              )}
            </InputLabel>
            <Select
              value={nameProject}
              sx={{
                borderRadius: "8px",
                width: "226.16px",
                [theme?.breakpoints?.down("xs")]: {
                  width: "auto",
                  marginBottom: "8px",
                  marginRight: "7px",
                },
              }}
              id="ProjectId"
              name="ProjectId"
              input={<OutlinedInput label="Proyectos" />}
              multiple
              onChange={(e) => handleChange(e)}
              size="small"
              variant="outlined"
              color={
                datauser.projectIdLength === true
                  ? noFilledTextfield
                  : colorTextFields
              }
              MenuProps={{ sx: { width: "150px" } }}
            >
              {data?.getAllUsers[0]?.Project?.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.form}>
          <TextField
            sx={{
              width: "226.16px",
              [theme?.breakpoints?.down("xs")]: {
                display: "flex",
                width: "auto",
              },
            }}
            id="password"
            size="small"
            name="password"
            type={values.showPassword ? "text" : "password"}
            error={!datauser.isValidPassword ? true : false}
            placeholder="password"
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.PASSWORD"
            )}
            value={datauser.password}
            onChange={validatePassword}
            helperText={
              datauser.password === "" ||
              datauser.password.length < 8 ||
              !datauser.isValidPassword
                ? t(
                    "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.PASSWORD_HELPER_TEXT"
                  )
                : ""
            }
            required
            color={
              datauser.password === "" ||
              datauser.password.length < 8 ||
              !datauser.isValidPassword
                ? noFilledTextfield
                : colorTextFields
            }
            InputProps={{
              sx: { borderRadius: "8px" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={{
              width: "226.16px",
              [theme?.breakpoints?.down("xs")]: {
                display: "flex",
                width: "auto",
              },
            }}
            id="passwordTwo"
            size="small"
            name="passwordTwo"
            type={valuesTwo.showPasswordTwo ? "text" : "password"}
            error={datauser.passwordTwo !== datauser.password ? true : false}
            placeholder={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.CONFIRM_PASSWORD_HELPER_TEXT"
            )}
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.CONFIRM_PASSWORD"
            )}
            value={datauser.passwordTwo}
            onChange={validatePassword2}
            helperText={
              datauser.passwordTwo === "" || datauser.passwordTwo.length < 8
                ? t(
                    "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.CONFIRM_PASSWORD_HELPER_TEXT"
                  )
                : ""
            }
            required
            color={
              datauser.passwordTwo === "" ||
              datauser.passwordTwo.length < 8 ||
              datauser.passwordTwo !== datauser.password ||
              !datauser.isValidPassword
                ? noFilledTextfield
                : colorTextFields
            }
            InputProps={{
              sx: { borderRadius: "8px" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordTwo}
                    onMouseDown={handleMouseDownPasswordTwo}
                    edge="end"
                  >
                    {valuesTwo.showPasswordTwo ? (
                      <VisibilityTwo />
                    ) : (
                      <VisibilityOffTwo />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <Snackbar
            open={datauser.password !== datauser.passwordTwo}
            autoHideDuration={200}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            <Alert color="warning">
              {t(
                "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.PASSWORDS_DONOT_MATCH"
              )}
            </Alert>
          </Snackbar>
        </div>
        <div>
          <Button
            className={classes.butreg}
            onClick={userRegister}
            disabled={isDisabledSubmit}
          >
            <h3>
              {t(
                "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.BUTTON_TEXT"
              )}
            </h3>
          </Button>
        </div>
      </form>
      <div>
        <Snackbar
          open={openMessage}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <Alert severity="success">
            {t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.SUCCESSFUL_ALERT"
            )}
          </Alert>
        </Snackbar>

        <Snackbar
          open={showAlertTitle}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <Alert severity="error">
            {t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CREATE_USER_MODAL.ERROR_ALERT"
            )}
          </Alert>
        </Snackbar>
      </div>
    </Fragment>
  );
};
export default RegisterForm;
