import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../../context/AuthProvider";
import { encryptData } from "../../../utils/encryptDecrypt";
import { CREATE_PIN_CODE } from "../../../controllers/pinCodesController";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import FormHelperText from "@mui/material/FormHelperText";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PasswordIcon from "@mui/icons-material/Password";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

const EMPTY_STRING = "";
const TRANSLATION_SHORTCUT = "VIEW_AREA.COMPONENT_ADD_CODE_MODAL";

const EMAIL_REGEX = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
const FULLNAME_REGEX = /^[A-ZÁÉÍÓÚÑ][a-záéíóúñ]+(\s[A-ZÁÉÍÓÚÑ][a-záéíóúñ]+)*$/;

function AddCode({ setTypeMessage, setView, setDataCode }) {
  // manejo de multi-idioma en los labels
  const { t } = useTranslation();

  // conjunto de códigos programados en la cerradura
  const { codesGlobal } = useContext(AuthContext);

  // id de la cerradura y del controlador al que pertenece
  const { id, controllerId } = useParams();

  // datos del codigo
  const [codeName, setCodeName] = useState(EMPTY_STRING);
  const [codePin, setCodePin] = useState(EMPTY_STRING);

  // datos del usuario (aplica si se enviará por correo)
  const [userName, setUserName] = useState(EMPTY_STRING);
  const [userEmail, setUserEmail] = useState(EMPTY_STRING);
  const [userNin, setUserNin] = useState(EMPTY_STRING);

  // comprobar si se enviará por correo el código
  const [sendEmail, setSendEmail] = useState(false);

  // apariencia de visibilidad del código
  const [showCodePin, setShowCodePin] = useState(false);

  // errores de validación
  const [errors, setErrors] = useState({});

  // mutación para la generación del código en la cerradura
  const [createPinCode] = useMutation(CREATE_PIN_CODE);

  // AUNQUE AÚN NO ESTA DEFINIDO pienso en una mutación para enviar el correo
  // const [sendLockCodeToUserEmail] = useMutation(SEND_PIN_CODE);

  // Manejamos el estado de los valores booleanos
  const handleToggle = (setter) => () => setter((prev) => !prev); // const handleClickShowPassword = () => setShowCodePin(!showCodePin);

  // Previene el comportamiento por defecto
  const handleDefaultBehavior = (event) => event.preventDefault();

  // Manejamos los eventos de desenfoque de entrada para los campos del formulario.
  // Recortamos los espacios en blanco al principio y al final del valor de entrada
  // actualizamos el estado de los datos del formulario.
  const handleInputBlur = (setter) => (event) => {
    const value = event.target.value.trim();
    if (value !== event.target.value) setter(value);
  };
  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    setErrors((prev) => ({ ...prev, [event.target.name]: EMPTY_STRING }));
  };
  const handleInputKeyPress = (event) => {
    // Usamos event.which para compatibilidad con navegadores antiguos
    const keyCode = event.which || event.keyCode;
    // solo se permiten digitos y la tecla backspace para borrar
    if (keyCode !== 8 && (keyCode < 48 || keyCode > 57))
      // se previene que se registre la tecla si no está permitida
      event.preventDefault();
  };

  /* ANTIGUA IMPLEMENTACIÓN

  const handleSubmit = async () => {
    if (valideForm()) {
      setTypeMessage("loading");

      let variables = {
        name: codeName,
        pin: codePin,
        enabled: true,
        type: "a",
        ControllerId: parseInt(controllerId),
        DeviceId: parseInt(id)
      };

      const codeCreated = await createPinCode({ variables });
      if (codeCreated.data.createPinCode.success) {
        variables.index = codeCreated.data.createPinCode.data.index;
        setTimeout(() => {
          setTypeMessage(null);
          setDataCode(variables);
          setView("type");
        }, 60000);
      } else {
        setTypeMessage("error");
      }
    }
  };
  */

  const handleSubmit = (event) => {
    // validamos los campos de formulario
    if (validateForm()) {
      // iniciamos la animación
      setTypeMessage("loading");
      // se programa el código en la cerradura
      registerCodeInTheDoorLock();
    }
  };

  const registerCodeInTheDoorLock = async () => {
    try {
      // ciframos el código de la cerradura
      const SECRET_KEY = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY ?? "";
      const CYPHER_PIN = await encryptData(codePin, SECRET_KEY);
      // preparamos los datos para la creación fisica en el dispositivo
      let variables = {
        name: codeName,
        pin: CYPHER_PIN,
        enabled: true,
        type: "a",
        ControllerId: parseInt(controllerId),
        DeviceId: parseInt(id)
      };
      if (sendEmail) {
        /*
        *******************************************************
        aquí irán los datos extra para hacerle llegar el código
        por correo al usuario (internamente el backend manda
        la orden para crear el código en la cerradura física,
        una vez esto ocurra, procede a enviar un email)
        ******************************************************* */
        console.log("Se debe enviar correo");
        // ciframos el número de identificación del usuario
        const CYPHER_NIN = await encryptData(userNin, SECRET_KEY);
        // agregamos los datos del usuario que recibirá el PIN
        variables.user = {
          name: userName,
          email: userEmail,
          idNumber: CYPHER_NIN,
          pinMessageOption: getPinGenerationOption()
        };
      }
      console.log(variables);
      console.log("enviando...");
      /* 
      *********************************************************
      MUTACIÓN ACTUAL PARA LA CREACIÓN DEL CÓDIGO DE CERRADURA
      *********************************************************
      */
      const codeCreated = await createPinCode({ variables });
      const result = codeCreated.data.createPinCode;
      if (result.success) {
        console.log({
          codeName,
          codePin,
          userName,
          userEmail,
          userNin
        });
        variables.index = result.data.index;
        setTimeout(() => {
          setTypeMessage(null);
          setDataCode(variables);
          setView("type");
        }, 60000);
        variables?.user &&
          console.log(`Enviando correo electrónico a ${variables.user.email}...

          Estimado ${
            variables.user.name
          }, se te ha generado un acceso a tu cerradura electronica.
          Tu Número de Identifación Personal (PIN) consiste en un código númerico de ${
            codePin.length
          } dígitos.
          Este código se generó ${
            variables.user.pinMessageOption > 0
              ? `a partir de tu Número de Identidad Nacional (NIN): ${userNin}. Tomando los ${
                  variables.user.pinMessageOption === 1
                    ? "3 primeros dígitos junto a los 3 últimos"
                    : "6 últimos dígitos"
                }`
              : "aleatoriamente"
          }.
          Cordialmente, Horus!`);
      } else {
        setTypeMessage("error");
      }
    } catch (error) {
      console.log("No fue posible programar el código en la cerradura");
      console.log(error);
    } finally {
      console.log("registerCodeInTheDoorLock() proceso terminado!");
    }
  };

  /* ANTIGUA IMPLEMENTACIÓN
  const valideKey = (event) => {
    var keyCode = event.which || event.keyCode;

    if (keyCode == 8) {
      // backspace.
      return true;
    } else if (keyCode >= 48 && keyCode <= 57) {
      // is a number.
      return true;
    } else {
      // other keys.
      event.preventDefault();
      return false;
    }
  };
  */

  /* ANTIGUA IMPLEMENTACIÓN 

  const valideForm = () => {
    if (codesGlobal.map((i) => i.name).includes(codeName.trim())) {
      setErrors((prev) => ({ ...prev, name: "Esta nombre ya existe" }));
      return false;
    }
    if (codeName.trim().length === 0) {
      setErrors((prev) => ({ ...prev, name: "Ingrese un nombre válido" }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, name: "" }));
    }
    if (codesGlobal.map((i) => i.pin).includes(codePin.trim())) {
      setErrors((prev) => ({
        ...prev,
        code: "Esta contraseña ya está en uso."
      }));
      return false;
    }
    if (codePin.trim().length === 0 || codePin.trim().length < 6) {
      setErrors((prev) => ({ ...prev, code: "Debe tener mínimo 6 dígitos." }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, code: "" }));
    }

    return true;
  };
  */

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!codeName.length) {
      newErrors.codeName = t(`${TRANSLATION_SHORTCUT}.ERRORS.VALID_NAME`);
      valid = false;
    }

    if (codePin.length !== 6) {
      newErrors.codePin = t(`${TRANSLATION_SHORTCUT}.ERRORS.VALID_CODE_PIN`);
      valid = false;
    }

    for (const code of codesGlobal) {
      console.log(code);
      //
      if (newErrors.codeName && newErrors.codePin) break;

      // comprobar unicidad del nombre del código
      if (!newErrors.codeName && code.name === codeName) {
        newErrors.codeName = t(`${TRANSLATION_SHORTCUT}.ERRORS.NAME_EXISTS`);
        valid = false;
      }
      // comprobar unicidad del PIN del código
      if (!newErrors.codePin && code.pin === codePin) {
        newErrors.codePin = t(`${TRANSLATION_SHORTCUT}.ERRORS.PIN_EXISTS`);
        valid = false;
      }
    }

    if (sendEmail) {
      // valida que se especifique el nombre del destinatario
      if (!userName || !FULLNAME_REGEX.test(userName)) {
        newErrors.userName = t(`${TRANSLATION_SHORTCUT}.ERRORS.VALID_NAME`);
        valid = false;
      } else if (userName.length < 8) {
        newErrors.userName = t(`${TRANSLATION_SHORTCUT}.ERRORS.SHORT_NAME`);
        valid = false;
      }
      // valida que se especifique el correo electronico
      if (!userEmail || !EMAIL_REGEX.test(userEmail)) {
        newErrors.userEmail = t(`${TRANSLATION_SHORTCUT}.ERRORS.VALID_EMAIL`);
        valid = false;
      }
      // valida la longitud del número de identificación nacional
      if (userNin.length < 6) {
        newErrors.userNin = t(`${TRANSLATION_SHORTCUT}.ERRORS.VALID_NIN`);
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  // const setRandomCode = () => setCodePin(String(Math.random()).split(".")[1].substring(0, 6));
  const setRandomDigitsAsLockCode = () =>
    setCodePin(Math.random().toString().slice(2, 8));

  /**
   * Logica para generar los 6 digitos que desbloquearán la cerradura como código
   * a partir del dato del NIN (Cédula). Hay dos formas en las que se puede generar
   * el cógido, una es tomando los 6 ultimos digitos y el otro es tomando los 3 primeros
   * dígitos y juntandolos con los 3 últimos. Por lo que es necesario una forma de que
   * aleatoriamente aplique ya sea el primer caso o el 2do.
   * caso 1 const lastSixDigits = userNin.slice(-6);
   * -> const firstThreeLastThreeDigits = userNin.slice(-3) + userNin.slice(0, 3);
   */
  const generateNinDigitsAsLockCode = () =>
    Math.random() < 0.5
      ? userNin.slice(-6) // lastSixDigits
      : userNin.substring(0, 3) + userNin.slice(-3); // firstThreeLastThreeDigits

  const getPinGenerationOption = () =>
    codePin.startsWith(userNin.substring(0, 3)) &&
    codePin.endsWith(userNin.slice(-3))
      ? 1
      : codePin === userNin.slice(-6)
      ? 2
      : 0;

  const setNinDigitsAsLockCode = () =>
    userNin.length < 6
      ? setErrors((prev) => ({
          ...prev,
          userNin: t(`${TRANSLATION_SHORTCUT}.ERRORS.VALID_NIN`)
        }))
      : setCodePin(generateNinDigitsAsLockCode());

  return (
    <Box sx={{ display: "flex", p: 8 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "120px",
            height: "120px",
            backgroundColor: "rgb(56, 142, 60, 0.08)",
            borderRadius: "50%"
          }}
        >
          <PasswordIcon color="primary" sx={{ fontSize: "50px" }} />
        </Box>
      </Box>
      <Box sx={{ width: "300px", px: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size="small"
              label={t(`${TRANSLATION_SHORTCUT}.LABELS.CODE_NAME`)}
              variant="outlined"
              name="codeName"
              value={codeName}
              onChange={handleInputChange(setCodeName)}
              onBlur={handleInputBlur(setCodeName)}
              fullWidth
              error={Boolean(errors.codeName)}
              helperText={errors.codeName}
            />
          </Grid>
          {sendEmail && (
            <>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label={t(`${TRANSLATION_SHORTCUT}.LABELS.USER_FULLNAME`)}
                  name="userName"
                  value={userName}
                  onChange={handleInputChange(setUserName)}
                  onBlur={handleInputBlur(setUserName)}
                  error={Boolean(errors.userName)}
                  helperText={errors.userName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label={t(`${TRANSLATION_SHORTCUT}.LABELS.USER_EMAIL`)}
                  name="userEmail"
                  value={userEmail}
                  onChange={handleInputChange(setUserEmail)}
                  onBlur={handleInputBlur(setUserEmail)}
                  error={Boolean(errors.userEmail)}
                  helperText={errors.userEmail}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label={t(`${TRANSLATION_SHORTCUT}.LABELS.USER_NIN`)}
                  name="userNin"
                  value={userNin}
                  inputProps={{ minLength: "6" }}
                  onChange={handleInputChange(setUserNin)}
                  onBlur={handleInputBlur(setUserNin)}
                  onKeyPress={handleInputKeyPress}
                  error={Boolean(errors.userNin)}
                  helperText={errors.userNin}
                  fullWidth
                />
              </Grid>
            </>
          )}
          <Grid item xs={sendEmail ? 6 : 9}>
            <FormControl
              size="small"
              variant="outlined"
              error={Boolean(errors.codePin)}
              fullWidth
            >
              <InputLabel htmlFor="doorlock-code">
                {t(`${TRANSLATION_SHORTCUT}.LABELS.CODE_PIN`)}
              </InputLabel>
              <OutlinedInput
                id="doorlock-code"
                name="codePin"
                type={showCodePin ? "text" : "password"}
                inputProps={{ maxLength: "6" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleToggle(setShowCodePin)}
                      onMouseDown={handleDefaultBehavior}
                      edge="end"
                    >
                      {showCodePin ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                value={codePin}
                onChange={handleInputChange(setCodePin)}
                onBlur={handleInputBlur(setCodePin)}
                onKeyPress={handleInputKeyPress}
                onPaste={handleDefaultBehavior}
                label={t(`${TRANSLATION_SHORTCUT}.LABELS.CODE_PIN`)}
              />
              <FormHelperText>{errors.codePin}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Tooltip
              title={t(`${TRANSLATION_SHORTCUT}.TOOLTIPS.GENERATE_RANDOM_CODE`)}
            >
              <Button
                color="inherit"
                variant="contained"
                disableElevation
                onClick={setRandomDigitsAsLockCode}
              >
                <PasswordIcon />
              </Button>
            </Tooltip>
          </Grid>
          {sendEmail && (
            <Grid item xs={3}>
              <Tooltip
                title={t(
                  `${TRANSLATION_SHORTCUT}.TOOLTIPS.GENERATE_CODE_BY_NIN`
                )}
              >
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={setNinDigitsAsLockCode}
                  disableElevation
                >
                  <VpnKeyIcon />
                </Button>
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={12} marginTop={-1.5}>
            <FormControlLabel
              control={
                <Switch
                  checked={sendEmail}
                  onChange={handleToggle(setSendEmail)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                <Box sx={{ typography: "body2" }}>
                  {t(`${TRANSLATION_SHORTCUT}.LABELS.SEND_EMAIL`)}
                </Box>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disableElevation
              fullWidth
            >
              {t(`${TRANSLATION_SHORTCUT}.BUTTONS.CREATE`)}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AddCode;
