// react hooks
import React from "react";

//React router Dom
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

//Material UI
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

//Images
import forgot_img from "../../assets/logo.svg";

//Apollo Hooks and Queries
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD } from "../../controllers/authenticationController.js";

//multilanguage
import { useTranslation } from "react-i18next";
import LanguageSelect from "../../components/languageSelect/LanguageSelect";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ForgotPassword = () => {
  const [toggle, setToggle] = React.useState("email");
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(null);
  const [errorEmail, setErrorEmail] = React.useState(null);
  const [sendEmail] = useMutation(FORGOT_PASSWORD);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const { t } = useTranslation();

  const handleState = (e) => {
    const { value } = e.target;
    setState(value);
  };

  const handleStateEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const submitEmail = () => {
    if (toggle === "user" && state === "") {
      setError(true);
    } else if (toggle === "email" && email === "") {
      setErrorEmail(true);
    }

    if (state || email) {
      try {
        sendEmail({
          variables: {
            user: state,
            email: email,
          },
        })
          .then((result) => {
            if (result.data.send_Email.data.enabled) {
              history.push("/confirmation");
            } else {
              setOpen(true);
            }
          })
          .catch((err) => {
            setOpen(true);
          });
      } catch (err) {}
    } else {
      console.error("");
    }
  };

  React.useEffect(() => {
    if (state !== "") {
      setError(false);
    }
    if (email !== "") {
      setErrorEmail(false);
    }
  }, [state]);

  return (
    <div className="main_container">
      <div className="forgotpass_container">
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert severity="error">
            {toggle === "user"
              ? t("VIEW_FORGOT_PASSWORD.ERROR_USER_ALERT")
              : t("VIEW_FORGOT_PASSWORD.ERROR_EMAIL_ALERT")}
          </Alert>
        </Snackbar>
        <div className="forgotpass_image_container">
          <img className="forgotpass_image" src={forgot_img} alt="img" />
        </div>
        <div className="forgot_pass_title_container">
          <span className="forgot_pass_title">
            {t("VIEW_FORGOT_PASSWORD.TITLE")}
          </span>
        </div>
        <div className="forgot_pass_subtitle_container">
          <span className="forgot_pass_subtitle">
            {t("VIEW_FORGOT_PASSWORD.PARAGRAPH")}
          </span>
        </div>
        <div className="forgot_pass_input_container">
          <div className="forgot_pass_input_and_title_container">
            {toggle === "user" ? (
              <>
                <div className="forgot_pass_input_title_container">
                  {t("VIEW_FORGOT_PASSWORD.USER_INPUT_LABEL")}
                </div>
                <input
                  className="forgot_pass_input"
                  type="text"
                  value={state}
                  placeholder={t("VIEW_FORGOT_PASSWORD.USER_INPUT_PLACEHOLDER")}
                  onChange={handleState}
                />
              </>
            ) : (
              <>
                <div className="forgot_pass_input_title_container">
                  {t("VIEW_FORGOT_PASSWORD.EMAIL_INPUT_LABEL")}
                </div>
                <input
                  className="forgot_pass_input"
                  type="email"
                  value={email}
                  placeholder={t(
                    "VIEW_FORGOT_PASSWORD.EMAIL_INPUT_PLACEHOLDER"
                  )}
                  onChange={handleStateEmail}
                />
              </>
            )}
            {(error || errorEmail) && (
              <div className="forgot_pass_span_required_input">
                {t("VIEW_FORGOT_PASSWORD.ERROR_INPUT")}
              </div>
            )}
            {toggle === "user" ? (
              <h4
                className="forgot_pass_toggle"
                onClick={() => {
                  setToggle("email");
                  setState("");
                }}
              >
                {t("VIEW_FORGOT_PASSWORD.RECOVERY_BY_EMAIL")}
              </h4>
            ) : (
              <h4
                className="forgot_pass_toggle"
                onClick={() => {
                  setToggle("user");
                  setEmail("");
                }}
              >
                {t("VIEW_FORGOT_PASSWORD.RECOVERY_BY_USER")}
              </h4>
            )}
          </div>
        </div>
        <div className="forgot_pass_submit_container">
          <button className="forgot_pass_submit" onClick={submitEmail}>
            {t("VIEW_FORGOT_PASSWORD.RECOVERY_PASSWORD_BUTTON")}
          </button>
        </div>
        <div className="forgot_pass_back_login_container">
          <span className="forgot_pass_back_login">
            <span style={{ display: "flex", gap: "3px" }}>
              <Link to="/">
                <KeyboardReturnIcon sx={{ marginTop: "2px" }} />
              </Link>
              <Link to="/">{t("VIEW_FORGOT_PASSWORD.RETURN_SIGN_IN")}</Link>
            </span>

            <LanguageSelect
              color={"black"}
              fontSize={"18px"}
              width={"110px"}
              iconHeight={"22px"}
              iconWidth={"22px"}
              borderRadious={"20px"}
              border={"none"}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
