import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import DateRangeIcon from "@mui/icons-material/DateRange";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";

import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

import { ADD_RESTRICTION } from "../../../controllers/pinCodesController";

const TRANSLATION_SHORTCUT = "VIEW_AREA.COMPONENT_RESTRICTION_MODAL";

const userLocale =
  localStorage.getItem("language") ??
  (navigator.languages?.length
    ? navigator.languages[0]
    : navigator.language
  ).split("-")[0];

function RangeRestriction({
  fromDate,
  toDate,
  handleChangeFromDate,
  handleChangeToDate,
  errors
}) {
  // manejo de multi-idioma en los labels
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "45px",
            height: "45px",
            backgroundColor: "rgb(56, 142, 60, 0.08)",
            borderRadius: "50%",
            marginRight: 1
          }}
        >
          <DateRangeIcon color="primary" fontSize="medium" />
        </Box>
        <Typography variant="h6">
          {t(`${TRANSLATION_SHORTCUT}.RANGE`) /* Rango */}
        </Typography>
      </Box>
      <Box sx={{ "& > :first-of-type": { marginRight: 2 }, py: 2 }}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={userLocale === "es" ? esLocale : enLocale}
        >
          <FormControl>
            <MobileDateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label={t(`${TRANSLATION_SHORTCUT}.FROM`) /* Desde */}
              value={fromDate}
              onChange={(date) => handleChangeFromDate(date)}
              inputFormat="eeee, dd MMMM HH:mm"
              minDateTime={new Date()}
              showToolbar={true}
              ampm={true}
            />
          </FormControl>
          <FormControl error={errors?.toDate ? true : false}>
            <MobileDateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label={t(`${TRANSLATION_SHORTCUT}.TO`) /* Hasta */}
              value={toDate}
              onChange={(date) => handleChangeToDate(date)}
              inputFormat="eeee, dd MMMM HH:mm"
              minDateTime={fromDate}
              showToolbar={true}
              ampm={true}
            />
            <FormHelperText>{errors?.toDate}</FormHelperText>
          </FormControl>
        </LocalizationProvider>
      </Box>
    </Box>
  );
}

function CustomRestriction({
  fromDate,
  toDate,
  days,
  handleChangeDays,
  handleChangeFromDate,
  handleChangeToDate,
  errors
}) {
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "45px",
            height: "45px",
            backgroundColor: "rgb(56, 142, 60, 0.08)",
            borderRadius: "50%",
            marginRight: 1
          }}
        >
          <EventRepeatIcon color="primary" fontSize="medium" />
        </Box>
        <Typography variant="h6">
          {t(`${TRANSLATION_SHORTCUT}.CUSTOM`) /* Personalizado */}
        </Typography>
      </Box>

      <Box sx={{ "& > :first-of-type": { marginRight: 2 }, py: 2 }}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <FormControl>
            <MobileTimePicker
              label={t(`${TRANSLATION_SHORTCUT}.FROM`) /* "Desde" */}
              value={fromDate}
              onChange={(date) => handleChangeFromDate(date)}
              renderInput={(params) => <TextField {...params} />}
              ampm={true}
            />
          </FormControl>
          <FormControl error={errors?.toDate ? true : false}>
            <MobileTimePicker
              label={t(`${TRANSLATION_SHORTCUT}.TO`) /* Hasta */}
              value={toDate}
              onChange={(date) => handleChangeToDate(date)}
              renderInput={(params) => <TextField {...params} />}
              ampm={true}
              minTime={new Date(new Date(fromDate).getTime() + 300000)}
            />
            <FormHelperText>{errors?.toDate}</FormHelperText>
          </FormControl>
        </LocalizationProvider>
      </Box>
      <Box sx={{ pt: 2 }}>
        <Typography variant="p" sx={{ marginRight: 2 }}>
          {t(`${TRANSLATION_SHORTCUT}.DAYS`) /* Dias: */}:
        </Typography>
        <FormControl error={errors?.days ? true : false}>
          <ToggleButtonGroup value={days} onChange={handleChangeDays}>
            <ToggleButton value="1">
              {t(`${TRANSLATION_SHORTCUT}.MON`) /* Lun */}
            </ToggleButton>
            <ToggleButton value="2">
              {t(`${TRANSLATION_SHORTCUT}.TUE`) /* Mar */}
            </ToggleButton>
            <ToggleButton value="3">
              {t(`${TRANSLATION_SHORTCUT}.WED`) /* Mie */}
            </ToggleButton>
            <ToggleButton value="4">
              {t(`${TRANSLATION_SHORTCUT}.THU`) /* Jue */}
            </ToggleButton>
            <ToggleButton value="5">
              {t(`${TRANSLATION_SHORTCUT}.FRI`) /* Vie */}
            </ToggleButton>
            <ToggleButton value="6">
              {t(`${TRANSLATION_SHORTCUT}.SAT`) /* Sab */}
            </ToggleButton>
            <ToggleButton value="7">
              {t(`${TRANSLATION_SHORTCUT}.SUN`) /* Dom */}
            </ToggleButton>
          </ToggleButtonGroup>
          <FormHelperText>{errors?.days}</FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );
}

function SelectType({ handleClickType }) {
  const { t } = useTranslation();

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: "center", paddingBottom: 2 }}>
          <Typography variant="p">
            {
              /* Selecciona el tipo de restricción a agregar */
              t(`${TRANSLATION_SHORTCUT}.SELECT_TYPE`)
            }
          </Typography>
        </Grid>
        <Grid item xs>
          <Box sx={{ p: 2, textAlign: "center" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "64px",
                height: "64px",
                backgroundColor: "rgb(56, 142, 60, 0.08)",
                borderRadius: "50%",
                margin: "auto auto 10px"
              }}
            >
              <DateRangeIcon color="primary" fontSize="large" />
            </Box>
            <Typography variant="h6">
              {t(`${TRANSLATION_SHORTCUT}.RANGE`) /* Rango */}
            </Typography>
            <Typography variant="p">
              {
                /* Permite seleccionar un rango de días en el que el código estará activo */
                t(`${TRANSLATION_SHORTCUT}.RANGE_DESCRIPTION`)
              }
            </Typography>
            <Box sx={{ marginTop: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => handleClickType("t")}
              >
                {t(`${TRANSLATION_SHORTCUT}.SELECT`) /* Seleccionar */}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs>
          <Box sx={{ p: 2, textAlign: "center", borderRadius: "15px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "64px",
                height: "64px",
                backgroundColor: "rgb(56, 142, 60, 0.08)",
                borderRadius: "50%",
                margin: "auto auto 10px"
              }}
            >
              <EventRepeatIcon color="primary" fontSize="large" />
            </Box>
            <Typography variant="h6">
              {t(`${TRANSLATION_SHORTCUT}.CUSTOM`) /* Personalizado */}
            </Typography>
            <Typography variant="p">
              {
                /* Permite seleccionar qué días y a qué hora estará activo en cada semana */
                t(`${TRANSLATION_SHORTCUT}.CUSTOM_DESCRIPTION`)
              }
            </Typography>
            <Box sx={{ marginTop: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => handleClickType("w")}
              >
                {t(`${TRANSLATION_SHORTCUT}.SELECT`) /* Seleccionar */}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function Restriction({ dataCode, setTypeMessage, refetchListCode }) {
  const { t } = useTranslation();

  const [type, setType] = useState(null);
  const [fromDate, setFromDate] = useState(new Date(new Date().getTime()));
  const [toDate, setToDate] = useState(
    new Date(new Date().getTime() + 86500000)
  );
  const [days, setDays] = useState([]);
  const [errors, setErrors] = useState({});
  const { controllerId } = useParams();

  const [addRestriction] = useMutation(ADD_RESTRICTION);

  useEffect(() => {
    refetchListCode();
  }, []);

  const handleChangeFromDate = (date) => setFromDate(date);
  const handleChangeToDate = (date) => setToDate(date);
  const handleChangeDays = (event, newDay) => {
    setDays(newDay);
  };

  const handleClickType = (type) => {
    setType(type);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (valideForm()) {
      setTypeMessage("loading");
      let variables = {
        ...dataCode,
        from: fromDate,
        to: toDate,
        days: days.toString().replace(/,/g, ""),
        type,
        ControllerId: parseInt(controllerId)
      };
      const restriction = await addRestriction({ variables });
      if (restriction.data.addRestriction.success) {
        setTypeMessage("success");
      } else {
        setTypeMessage("error");
      }
    }
  };

  const goBack = () => {
    setType(null);
    setDays([]);
    setFromDate(new Date(new Date().getTime()));
    setToDate(new Date(new Date().getTime() + 86500000));
    setErrors({});
  };

  const viewType = () => {
    switch (type) {
      case "t":
        return (
          <RangeRestriction
            fromDate={fromDate}
            toDate={toDate}
            handleChangeFromDate={handleChangeFromDate}
            handleChangeToDate={handleChangeToDate}
            errors={errors}
          />
        );
      case "w":
        return (
          <CustomRestriction
            fromDate={fromDate}
            toDate={toDate}
            handleChangeFromDate={handleChangeFromDate}
            handleChangeToDate={handleChangeToDate}
            handleChangeDays={handleChangeDays}
            days={days}
            errors={errors}
          />
        );
      default:
        return <SelectType handleClickType={handleClickType} />;
    }
  };

  const valideForm = () => {
    let diff = toDate.getTime() - fromDate.getTime();
    if (diff < 0) {
      if (type === "t") {
        setErrors((prev) => ({
          ...prev,
          toDate: t(`${TRANSLATION_SHORTCUT}.SELECT_LATER_DATE`) // "Seleccione una fecha mayor"
        }));
        return false;
      } else if (type === "w") {
        setErrors((prev) => ({
          ...prev,
          toDate: t(`${TRANSLATION_SHORTCUT}.SELECT_LATER_TIME`) // "Seleccione una hora mayor"
        }));
        return false;
      }
    }

    if (type === "w" && days.length === 0) {
      setErrors((prev) => ({
        ...prev,
        days: t(`${TRANSLATION_SHORTCUT}.SELECT_AT_LEAST_ONE_DAY`) // "Seleccione como mínimo un día de la semana"
      }));
      return false;
    }
    setErrors({});
    return true;
  };

  return (
    <Box sx={{ p: 3 }}>
      {viewType()}
      {type && (
        <Box>
          <Box sx={{ textAlign: "right", pt: 3 }}>
            <Button
              color="inherit"
              disableElevation
              onClick={goBack}
              sx={{ marginRight: 2 }}
            >
              {t(`${TRANSLATION_SHORTCUT}.BACK`) /* Atrás */}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disableElevation
            >
              {t(`${TRANSLATION_SHORTCUT}.SAVE`) /* Guardar */}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Restriction;
