import gql from "graphql-tag";

// Se agrega el campo del usuario destinatario para envió del código por correo
export const CREATE_PIN_CODE = gql`
    mutation (
        $ControllerId: Int!
        $DeviceId: Float!
        $name: String!
        $pin: String!
        $enabled: Boolean
        $type: String
        $user: RecipientUserInput
    ) {
        createPinCode(
            ControllerId: $ControllerId
            DeviceId: $DeviceId
            name: $name
            pin: $pin
            enabled: $enabled
            type: $type
            user: $user
        )
    }
`;

export const GET_SENT_PIN_CODE_REPORT = gql`
    query ($id: Int, $pin: String, $idNumber: String) {
        getSentPinCodeReport(
            id: $id,
            code: $pin,
            idNumber: $idNumber
        ) {
            id
            code
            date
            idNumber
            user {
                email
                idNumber
                name
            }
        }
    }
`;

export const SEND_PIN_CODE_BY_EMAIL = gql`
    mutation ($pin: String!, $user: RecipientUserInput!) {
        resendPinCodeByEmail(pin: $pin, user: $user)
    }`;

export const ADD_RESTRICTION = gql`
    mutation (
        $ControllerId: Int
        $DeviceId: Float
        $index: Int
        $type: String
        $from: String
        $to: String
        $days: String
        ) {
            addRestriction(
                ControllerId: $ControllerId
                DeviceId: $DeviceId
                index: $index
                type: $type
                from: $from
                to: $to
                days: $days
            )
        }`

export const GET_PIN_CODES = gql`
    query(
        $id: Int
        $index: Int
        $name: String
        $pin: String
        $enabled: Boolean
        $type: String
        $from: String
        $to: String
        $days: String
        $DeviceId: Float
    ){
        getPinCodes(
            id: $id
            index: $index
            name: $name
            pin: $pin
            enabled: $enabled
            type: $type
            from: $from
            to: $to
            days: $days
            DeviceId: $DeviceId
        ){
            id
            index
            name
            pin
            enabled
            type
            from
            to
            days
            DeviceId
        }
    }
`