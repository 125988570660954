import styled from "styled-components";

export const WrapperCurtainSwitch = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px;
  height: 145px;
  border: #dbdfdf 1px solid;
  border-radius: 15px;
  box-shadow: 1px 2px 1px 1px rgba(grey, 0.3);
`;
export const HeaderCurtainswitch = styled.div`
  //background-color: #E956CC;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-column-end: 6;
  border-radius: 15px 15px 0px 0px;
  box-shadow: 1px 2px 1px 1px rgba(grey, 0.3);
  background-color: ${(props) =>
    props.onLine ? "rgba(241, 241, 241, 1)" : "rgba(191, 38, 38, 0.3)"};
  display: flex;
  justify-items: flex-start;
  align-items: center;
  padding-left: 5%;
  font-size: 17px;
`;

export const WrapperCurtainIcon = styled.div`
  //background-color: #DA9EAC;
  grid-row-start: 2;
  grid-column-start: 1;
  grid-row-end: 4;
  grid-column-end: 3;
  border-radius: 0px 0px 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const WrapperActions = styled.div`
  //background-color: #7BBD69;
  grid-row-start: 2;
  grid-column-start: 3;
  grid-row-end: 4;
  grid-column-end: 6;
  border-radius: 0px 0px 15px 0px;
  padding-right: 2rem;
  padding-top: 0.5rem;
  gap: 15px;
`;
export const WrapperActionsButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ActionsButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e2e3e5;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  font-size: 15px;
  padding: 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #929e9e;
  }
`;
