import { FC, Fragment, useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";

import MoreVertIcon from "@mui/icons-material/MoreVert";

const slotDropdownMenuProps = {
    paper: {
        elevation: 0,
        sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
            },
            "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0
            }
        }
    }
};

const transformOrigin = {
    horizontal: "right",
    vertical: "top"
};
const anchorOrigin = {
    ...transformOrigin,
    vertical: "bottom"
};

/**
 * Renders a list of MenuItems.
 * @param {FC<{ items: Array<{ text: string, icon: FC, onClick: () => void }> }>} props
 * @param {Array<{ text: string, icon: FC, onClick: () => void }>} items - The array of items to render in the dropdown menu.
 * @param {string} items[].text - The text value to display in the menu item.
 * @param {FC} items[].renderIcon - The icon component to display in the menu item.
 * @param {Function} items[].onClick - The function to call when the menu item is clicked.
 * @returns {JSX.Element[]} The list of rendered menu items.
 */
const MenuItemList = ({ items }) =>
    items.map((item, index) => (
        <MenuItem key={`dropdown-item-${index + 1}`} onClick={item.onClick}>
            <ListItemIcon>{item.renderIcon()}</ListItemIcon>
            {item.text}
        </MenuItem>
    ));

/**
 * DropstartDropdown component renders a button that opens a dropdown menu with customizable options.
 *
 * @FC
 * @param {Object} props - The component props.
 * @param {string} props.title - The title for the tooltip displayed on the icon button.
 * @param {Array<{ text: string, renderIcon: React.ReactNode, onClick: () => void }>} props.items - The items to display in the dropdown menu.
 *
 * @example
 * const items = [
 *   { text: "profile", icon: () => <Settings fontSize="small" />, onClick: () => alert("profile") },
 *   { text: "account", icon: () => <Logout fontSize="small" />, onClick: () => alert("account") },
 *   { text: "logout", icon: () => <Settings fontSize="small" />, onClick: () => alert("logout") }
 * ];
 * return (
 *   <DropstartDropdown items={items} />
 * );
 *
 * @returns {JSX.Element} The rendered FC.
 */
export default function DropstartDropdown({ title, items }) {
    const [anchorElement, setAnchorElement] = useState(null);
    const open = Boolean(anchorElement);

    /**
     * Handles the click event on the button and sets the anchor element.
     *
     * @param {React.MouseEvent<HTMLButtonElement>} event - The mouse click event.
     */
    const handleClick = (event) => setAnchorElement(event.currentTarget);
    const handleClose = () => setAnchorElement(null);

    return (
        <Fragment>
            <Tooltip title={title}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ mx: 1.8 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                >
                    <MoreVertIcon sx={{ color: "#000" }} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorElement}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={slotDropdownMenuProps}
                transformOrigin={transformOrigin}
                anchorOrigin={anchorOrigin}
            >
                <MenuItemList items={items} />
            </Menu>
        </Fragment>
    );
}
