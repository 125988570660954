//React Hooks
import React, { useState } from "react";

//Material UI components & Styles
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

//Apollo hooks & Queries
import { useMutation } from "@apollo/client";
import { UPDATE_CONTROLLER } from "../../controllers/roomControllers";

//Multilanguage
import { useTranslation } from "react-i18next";

export const ModelEditController = ({ open, handlerClose, data, refetch }) => {
  const customGreen = "#acf6ac";
  const theme = createTheme({
    palette: {
      success: {
        main: customGreen,
      },
    },
  });

  const projects = JSON.parse(localStorage.getItem("project"));
  const projectIdAssociated = localStorage.getItem("id");
  const { t } = useTranslation();
  const [updatedController] = useMutation(UPDATE_CONTROLLER);
  const [editName, setEditName] = useState(false);
  const [editProject, setEditProject] = useState(false);
  const [errors, setErrors] = useState({ name: false, projectId: false });
  const [dataEdit, setDataEdit] = useState({
    name: data?.item?.name,
    projectId: Number(projectIdAssociated),
  });

  const handlerEditName = () => {
    setEditName(!editName);
  };
  const handlerEditProject = () => {
    setEditProject(!editProject);
  };

  const handlerEditTextName = (e) => {
    setDataEdit({ ...dataEdit, name: e.target.value });
    setErrors((prev) => ({ ...prev, name: false }));
  };

  const handlerEditIdProject = (e) => {
    const { value } = e.target;
    setDataEdit({ ...dataEdit, projectId: value });
    setErrors((prev) => ({ ...prev, projectId: false }));
  };

  const validateFields = () => {
    const nameError = dataEdit.name.trim() === "";
    const projectIdError = !dataEdit.projectId;

    setErrors({ name: nameError, projectId: projectIdError });
    return !(nameError || projectIdError);
  };

  const activeButton = (e) => {
    const name = dataEdit?.name;
    const projectId = dataEdit?.projectId;
    if (
      name !== data?.item?.name ||
      projectId !== Number(projectIdAssociated)
    ) {
      return true;
    } else if (name === "") {
      return false;
    }
  };

  const updated = () => {
    if (!validateFields()) {
      return console.error("error no estan validados los campos");
    }
    try {
      updatedController({
        variables: {
          updateControllerId: data?.item?.id,
          typeGateway: data?.item?.typeGateway,
          data: {
            name: dataEdit?.name,
            ProjectId: dataEdit?.projectId,
          },
        },
      })
        .then((resul) => {
          if (resul.data && resul.data.error === "validation error") {
            console.error("Error de validacion", resul.data.message);
          } else {
            setTimeout(() => {
              refetch();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const isValid = (value) =>
    value !== "" && value !== undefined && value !== null;

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          display: "flex",
          borderRadius: "8px",
          width: "35%",
          height: "auto",
          [theme.breakpoints.down("md")]: {
            width: "45%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "90%",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#2EB142",
          color: "#FFFFFF",
          lineHeight: "2.6",
          padding: "5px 5px 5px 40px",
        }}
      >

        <span
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            fontFamily: "Montserrat, sans-serif",
            fontSize: "auto",
            fontWeight: "bold",
          }}
        >
          {t("COMPONENT_EDIT_CONTROLLER.TITLE")}
        </span>
        <IconButton onClick={handlerClose}>
          <CloseIcon sx={{ color: "#ffff" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "25px",
          padding: "20px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "90%",
            fontFamily: "Montserrat, sans-serif",
            fontSize: "18px",
            marginTop: "20px",
          }}
        >
          <span>
            <b>Id:</b> {data?.item?.id}
          </span>

          <span>
            <b>Serial:</b> {data?.item?.serial}
          </span>

          {isValid(data?.item?.firmware) && (
            <span>
              <b>Firmware:</b> {data?.item?.firmware}
            </span>
          )}
          {isValid(data?.item?.model) && (
            <span>
              <b>Model:</b> {data?.item?.model}
            </span>
          )}

          <span>
            <b>TypeGateway:</b> {data?.item?.typeGateway}
          </span>


          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <TextField
              helperText={
                errors.name && t("COMPONENT_EDIT_CONTROLLER.ALERT_TEXT")
              }
              variant="outlined"
              size="small"
              InputProps={{
                sx: { borderRadius: "8px" },
              }}
              sx={{ width: "100%" }}
              placeholder="Nombre"
              defaultValue={data?.item?.name}
              disabled={!editName}
              label={t("COMPONENT_EDIT_CONTROLLER.CONTROLLER_NAME")}
              onChange={handlerEditTextName}
            />
            <EditOutlinedIcon
              onClick={handlerEditName}
              sx={{
                placeContent: "center",
                cursor: "pointer",
                color: "#909090",
                "&:hover": { color: "#626262" },
              }}
            />
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <TextField
              helperText={
                errors.projectId &&
                t("COMPONENT_EDIT_CONTROLLER.ALERT_ID_PROJECT")
              }
              size="small"
              InputProps={{
                sx: { borderRadius: "8px" },
              }}
              sx={{ width: "100%" }}
              disabled={!editProject}
              placeholder="ProjectId"
              label={t("COMPONENT_EDIT_CONTROLLER.CHANGE_CONTROLLER")}
              id="projectId"
              select={<multiple />}
              defaultValue={projectIdAssociated}
              onChange={handlerEditIdProject}
              slotProps={{
                select: {
                  native: true,
                },
              }}
            >
              {projects?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <EditOutlinedIcon
              onClick={handlerEditProject}
              sx={{
                placeContent: "center",
                cursor: "pointer",
                color: "#909090",
                "&:hover": { color: "#626262" },
              }}
            />
          </div>
          <ThemeProvider theme={theme}>
            <Button
              color="success"
              disabled={activeButton() === true ? false : true}

              onClick={updated}
              sx={{
                width: "100%",
                padding: "7px",
                color: "#000",
                borderRadius: "8px",
                lineHeight: "18px",
              }}
              variant="contained"
            >
              {t("COMPONENT_EDIT_CONTROLLER.BUTTON")}
            </Button>
          </ThemeProvider>
        </div>
      </DialogContent>
    </Dialog>
  );
};
