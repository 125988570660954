//React hooks
import { useContext, useState, useEffect } from "react";

//Global context
import { AuthContext } from "../../../context/AuthProvider";

//React Highchart
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

//Apollo hooks and Queries
import { useLazyQuery } from "@apollo/client";
import { GET_HISTORY_TWO } from "../../../controllers/measurersController";
import { secondClient } from "../../..";

//Components
import { ReactDateRangePicker } from "../../reactDateRange/index";

//multilanguage
import { useTranslation } from "react-i18next";
// import DatePicker from "../../globals/datepicker/datepicker";

export const NewGraphConsumptionDetailPerMonth = ({ serial, city }) => {
  const codigosCiudades = {
    Bogotá: "BG",
    Barranquilla: "BQ",
    default: "BQ",
  };
  Object.freeze(codigosCiudades);

  const { t } = useTranslation();
  const [datas, setDatas] = useState(0);
  const {
    setSeeGraphic,
    rangeDatePicker,
    setRangeDatePicker,
    setRangeDatePickerToday,
    globalReactDateRange,
    setGlobalReactDateRange,
  } = useContext(AuthContext);

  const [getHistoryTwo, { data }] = useLazyQuery(GET_HISTORY_TWO, {
    client: secondClient,
    fetchPolicy: "no-cache",
  });

  const fetchHistoryData = (serial, startTime, endTime, location) => {
    getHistoryTwo({
      variables: {
        serial: serial,
        starTime: startTime,
        endTime: endTime,
        location: location,
      },
    });
  };
  
  useEffect(() => {
    if (datas !== 0) {
      // setRangeDatePicker([
      //   {
      //     ...rangeDatePicker.selection,
      //     startDate: new Date(new Date()?.getTime() - 2589000000),
      //     endDate: new Date(),
      //     key: "selection",
      //   },
      // ]);
      // setRangeDatePickerToday([
      //   {
      //     ...rangeDatePicker.selection,
      //     startDate: new Date(new Date()?.getTime() - 2589000000),
      //     endDate: new Date(),
      //     key: "selection",
      //   },
      // ]);
    }

    setGlobalReactDateRange([
      {
        startDate: new Date(new Date()?.getTime() - 2589000000),
        endDate: new Date(),
        key: "selection",
      },
    ]);

    fetchHistoryData(
      serial,
      new Date(new Date()?.getTime() - 2589000000),
      globalReactDateRange[0]?.endDate,
      codigosCiudades[city] || codigosCiudades.default
    );
  }, []);

  const handleFetchData = () => {
    fetchHistoryData(
      serial,
      globalReactDateRange[0]?.startDate,
      globalReactDateRange[0]?.endDate,
      codigosCiudades[city] || codigosCiudades.default
    );
  };

  useEffect(() => {
    if (globalReactDateRange) {
      handleFetchData();
    }
  }, [globalReactDateRange]);

  useEffect(() => {
    setSeeGraphic(false);
  }, []);

  return (
    <>
      {/* <div className="container_datePicker_macrometer">
        <DatePicker
          handleQuery={handleFetchData}
          type="range"
          selectOption={{ changeInside: false, stateRoomId: 0 }}
          component={"macrometer"}
        />
      </div> */}

      <ReactDateRangePicker
        selectTypeRange="m"
        closingTime={0}
        closingType="n"
      />

      <div className="__details_measurer_consumption_graphic__">
        {data?.getConsumptionHistory?.length === 0 ? (
          <></>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                zooming: {
                  mouseWheel: false,
                },
                type: "column",
              },
              colors: ["#04b431"],
              colors: ["#04b431"],

              title: {
                align: "left",
                text: `${t(
                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS.CONSUMPTION_GRAPH_TITLE"
                )} ${
                  data?.getConsumptionHistory?.currentConsumption?.activeEnergy
                    ? data?.getConsumptionHistory?.currentConsumption?.activeEnergy
                    : datas
                } kWh `,
              },

              subtitle: {
                align: "right",

                style: {
                  color: "#000",
                  fontSize: "16px",
                },
                y: 40,
              },
              accessibility: {
                announceNewData: {
                  enabled: true,
                },
              },
              xAxis: {
                type: "category",
                type: "category",
              },
              yAxis: {
                title: {
                  text: "",
                  text: "",
                },
              },

              plotOptions: {
                column: {
                  grouping: false,
                  shadow: false,
                  borderRadius: 4,
                  borderWidth: 2,
                  borderColor: "black",
                },
                series: {
                  dataLabels: {
                    enabled: true,
                    format: "{point.y:.1f}",
                    format: "{point.y:.1f}",
                  },
                },
              },
              legend: {
                shadow: false,
              },
              tooltip: {
                shared: true,
                // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat:
                  '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b> kWh<br/>',
              },
              series: data?.getConsumptionHistory?.series,
              credits: {
                enabled: false,
              },
            }}
          />
        )}
      </div>
    </>
  );
};
