import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MONTHS_NUMBER } from "../../../../utils/helpers";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import DropstartDropdown from "./DropstartDropdown";

import BlockIcon from "@mui/icons-material/Block";
import EventIcon from "@mui/icons-material/Event";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HistoryIcon from "@mui/icons-material/History";

const TRANSLATION_SHORTCUT = "VIEW_PROJECT.VIEW_CODE_TABLE"; // TRANSLATION_SHORTCUT

const RangeCodesList = ({ data }) => {
  // agregando multi-idioma
  const { t } = useTranslation();

  const splitTo = data.to.split(" ");
  const splitFrom = data.from.split(" ");
  const getHourTo = splitTo[1];
  const getHourFrom = splitFrom[1];
  const getYearTo = splitTo[0].split("-")[0];
  const getDayTo = splitTo[0].split("-")[2];
  const getMountTo = splitTo[0].split("-")[1];
  const getYearFrom = splitFrom[0].split("-")[0];
  const getDayFrom = splitFrom[0].split("-")[2];
  const getMountFrom = splitFrom[0].split("-")[1];

  return (
    <div className="__content_range_restriction">
      <div className="___content_range_content">
        <div className="____title_range_restrict">
          {t(`${TRANSLATION_SHORTCUT}.RANGE_FROM`) /* Desde */}
        </div>
        <div className="____date_range_restrict">{`${getDayFrom} ${
          MONTHS_NUMBER[Number(getMountFrom)]
        } ${getYearFrom}`}</div>
        <div className="____hour_range_restrict">{getHourFrom}</div>
      </div>
      <div className="___content_range_content">
        <div className="____title_range_restrict">
          {t(`${TRANSLATION_SHORTCUT}.RANGE_TO`) /* Hasta */}
        </div>
        <div className="____date_range_restrict">{`${getDayTo} ${
          MONTHS_NUMBER[Number(getMountTo)]
        } ${getYearTo}`}</div>
        <div className="____hour_range_restrict">{getHourTo}</div>
      </div>
    </div>
  );
};

const LabelDaysCodeList = ({ days }) => {
  const [labels, setLabels] = useState([
    { key: 1, label: "L" },
    { key: 2, label: "M" },
    { key: 3, label: "X" },
    { key: 4, label: "J" },
    { key: 5, label: "V" },
    { key: 6, label: "S" },
    { key: 7, label: "D" }
  ]);

  useEffect(() => {
    days.split("").map((chart) => {
      setLabels((prev) =>
        prev.map((label) => {
          if (label.key == chart) {
            return { ...label, color: "primary" };
          }
          return label;
        })
      );
    });
  }, [days]);
  return (
    <Stack direction="row" spacing={1}>
      {labels.map((day) => (
        <Chip {...day} size="small" />
      ))}
    </Stack>
  );
};

const HIDDEN_PIN_REGEX = /^\*+$/;

const TableListCodes = ({
  listCodes,
  handleOpenModal,
  handleAddRestriction,
  handleViewHistoryOfSentPasswords,
  handleViewSendCodeToPerson
}) => {
  // agregando multi-idioma
  const { t } = useTranslation();

  // valor del cuadro de busqueda que se usa para filtrar por nombre los códigos
  const [nameFilter, setNameFilter] = useState("");

  // se actualizó a enable para que el filtro muestre por defecto los codigos habilitados
  const [statusFitler, setStatusFilter] = useState("enable");

  const orderCodes = listCodes.sort((a, b) => b.id - a.id);

  const restrictionLabel = {
    a: t(`${TRANSLATION_SHORTCUT}.ALWAYS`), // "Siempre"
    t: t(`${TRANSLATION_SHORTCUT}.RANGE`), // "Rango"
    w: t(`${TRANSLATION_SHORTCUT}.CUSTOM`) // "Personalizado"
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setNameFilter(value);
  };

  const handleChangeStatus = (e) => {
    const { value } = e.target;
    setStatusFilter(value);
  };

  const filtersByNameStatus = (data) => {
    if (nameFilter != "") {
      return data.filter((i) => i.name.toLowerCase().includes(nameFilter));
    }
    if (statusFitler === "all") {
      return data;
    }
    if (statusFitler === "enable") {
      return data.filter((i) => i.enabled);
    }
    if (statusFitler === "disabled") {
      return data.filter((i) => !i.enabled);
    }
    return data;
  };

  // const getLockCodeManagementActions = (lockCodeState = true) =>
  //   lockCodeState
  //   ? [
  //       {
  //         text: "Modificar rango actual",
  //         renderIcon: () => <Settings fontSize="small" />,
  //         onClick: () => console.log("Modificar rango actual")
  //       },
  //       {
  //         text: "Enviar código",
  //         renderIcon: () => <Delete fontSize="small" />,
  //         onClick: () => <SendLockCode />
  //       }
  //     ]
  //   : [
  //       {
  //         text: "Ver códigos enviados",
  //         renderIcon: () => <Settings fontSize="small" />,
  //         onClick: () => console.log("Ver códigos enviados")
  //       },
  //       {
  //         text: "Modificar rango actual",
  //         renderIcon: () => <Settings fontSize="small" />,
  //         onClick: () => console.log("Modificar rango actual")
  //       },
  //     ];

  return (
    <div className="tablelistcodes_container">
      <div className="tablelistcodes_content_header">
        <div className="__content_header_title">
          <h1>
            {
              /* Lista de códigos */
              t(`${TRANSLATION_SHORTCUT}.CODE_LIST_TITLE`)
            }
          </h1>
          <span>
            {
              /* Aquí podrás ver y gestionar todos tus códigos */
              t(`${TRANSLATION_SHORTCUT}.CODE_LIST_DESCRIPTION`)
            }
          </span>
        </div>
        <div className="__content_header_filters">
          <div className="__content_header_filters_container">
            <span className="__content_header_filters__title">
              {
                /* Filtrar por nombres */
                t(`${TRANSLATION_SHORTCUT}.FILTER_BY_NAME`)
              }
            </span>
          </div>
          <div className="__content_header_filters_container">
            <input
              type="text"
              className="__content_header_filters_input"
              placeholder={
                t(`${TRANSLATION_SHORTCUT}.ENTER_NAME`) /*"Ingresa un nombre" */
              }
              name="filter_name_codes"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="__content_header_filters_status">
          <div className="__content_header_filters_container_status">
            <span className="__content_header_filters_status_title">
              {
                /* Filtrar por status */
                t(`${TRANSLATION_SHORTCUT}.FILTER_BY_STATUS`)
              }
            </span>
          </div>
          <div className="__content_header_filters_container_status">
            <select
              name="filter_status"
              className="__content_header_filters_status_input"
              onChange={(e) => handleChangeStatus(e)}
              // estableciendo valor de habilitados por defecto en el combobox
              defaultValue="enable"
            >
              <option value="all">
                {t(`${TRANSLATION_SHORTCUT}.ALL`) /*Todos*/}
              </option>
              <option value="enable">
                {t(`${TRANSLATION_SHORTCUT}.ACTIVE`) /* Activos */}
              </option>
              <option value="disabled">
                {t(`${TRANSLATION_SHORTCUT}.INACTIVE`) /* Inactivos */}
              </option>
            </select>
          </div>
        </div>
        <div className="__content_header_button">
          <button onClick={() => handleOpenModal()}>
            <i className="fas fa-plus"></i> {/* Agregar código */}
            {t(`${TRANSLATION_SHORTCUT}.ADD_CODE`)}
          </button>
        </div>
      </div>
      <div className="tablelistcodes_content_header_table">
        <span>{t(`${TRANSLATION_SHORTCUT}.NO`) /* No: */}</span>
        <span>{t(`${TRANSLATION_SHORTCUT}.NAME`) /* Nombre */}</span>
        <span>
          {t(`${TRANSLATION_SHORTCUT}.RESTRICTION`) /* Restricción */}
        </span>
        <span>{t(`${TRANSLATION_SHORTCUT}.TYPE`) /* Tipo */}</span>
        <span>{t(`${TRANSLATION_SHORTCUT}.STATUS`) /* Estado */}</span>
        <span>{t(`${TRANSLATION_SHORTCUT}.ACTIONS`) /* Acciones */}</span>
      </div>
      <>
        {filtersByNameStatus(orderCodes).map((i, index) => {
          const dropdownItems = [];
          const IS_HIDDEN_PIN = HIDDEN_PIN_REGEX.test(i.pin);

          if (!IS_HIDDEN_PIN) {
            dropdownItems.push({
              text: t(`${TRANSLATION_SHORTCUT}.MODIFY_CURRENT_RANGE`), //"Modificar rango actual",
              renderIcon: () => <EventIcon fontSize="small" />,
              onClick: () => handleAddRestriction(i)
            });
            if (i.enabled)
              dropdownItems.push({
                text: t(`${TRANSLATION_SHORTCUT}.SEND_CODE_TO_PERSON`), // "Enviar código a persona",
                renderIcon: () => <MailOutlineIcon fontSize="small" />,
                onClick: () => handleViewSendCodeToPerson(i)
              });
            else
              dropdownItems.unshift({
                text: t(`${TRANSLATION_SHORTCUT}.SENT_HISTORY`), // "Historial de enviados",
                renderIcon: () => <HistoryIcon fontSize="small" />,
                onClick: () => handleViewHistoryOfSentPasswords(i)
              });
          }

          return (
            <div
              key={`code-${index}`}
              className={`tablelistcodes_content_header_codes ${
                index % 2 !== 0 ? "" : "tablelistcodes_content_header_codes_bg"
              }`}
            >
              <div className="__div__">{`${index + 1}`}</div>
              <div className="__content_codes_name_pass">
                <div className="___content_name">{i.name}</div>
                <div className="___content_pin">
                  {IS_HIDDEN_PIN
                    ? t(
                        `${TRANSLATION_SHORTCUT}.MASTER_KEY`
                      ) /* "Clave maestra" */
                    : i.pin}
                </div>
              </div>
              <div className="__content_code_add_restriction">
                {i.type === "a" ? (
                  <div className="___content_add_text">
                    {/* Sin restricción */}
                    {t(`${TRANSLATION_SHORTCUT}.NO_RESTRICTION`)}
                  </div>
                ) : (
                  <></>
                )}
                {i.type === "w" && i.days ? (
                  <LabelDaysCodeList days={i.days} />
                ) : (
                  <></>
                )}
                {i.type === "t" && i.to && i.from ? (
                  <RangeCodesList data={i} />
                ) : (
                  <></>
                )}
              </div>
              <div className="__div__">{restrictionLabel[i.type]}</div>
              <div
                className={
                  i.enabled
                    ? "__codes_status_activa"
                    : "__codes_status_inactiva"
                }
              >
                {
                  i.enabled
                    ? t(`${TRANSLATION_SHORTCUT}.ACTIVE_ITEM`) // "Activa"
                    : t(`${TRANSLATION_SHORTCUT}.INACTIVE_ITEM`) // "Inactiva"
                }
              </div>
              <div
                className="__acciones_codelist"
                // onClick={() => handleAddRestriction(i)} // Antigua implementación
              >
                {IS_HIDDEN_PIN ? (
                  <Tooltip
                    title={
                      /* "Este código no permite interacción" */
                      t(`${TRANSLATION_SHORTCUT}.NO_INTERACTION`)
                    }
                    sx={{ mx: 1.2 }}
                  >
                    <IconButton>
                      <BlockIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <DropstartDropdown
                    title={
                      t(`${TRANSLATION_SHORTCUT}.OPTIONS`) /* "opciones" */
                    }
                    items={dropdownItems}
                  />
                )}
              </div>
            </div>
          );
        })}
      </>
    </div>
  );
};

export default TableListCodes;
