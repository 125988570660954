//React hooks
import React, { useEffect, useState } from "react";

//React router dom elements
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

//Material UI
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

//Compoments
import { Validations } from "../../../components/userManagement/editUsers/chagesPassword/style/changesPasswordForm.style";

//helper functions
import { encryptData } from "../../../utils/encryptDecrypt";

//Images
import reset_password from "../../../assets/logo.svg";

//Apollo hooks and Queries
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../../controllers/authenticationController";

//Multilanguage
import { useTranslation } from "react-i18next";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ResetPassword() {
  const password = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY;
  const { t } = useTranslation();
  const { token } = useParams();
  const [passwords, setPasswords] = useState({ pass: "", confirmPass: "" });
  const [open, setOpen] = useState(false);
  const [openReponseAlert, setOpenResponseAlert] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const [create_Reg_Password] = useMutation(RESET_PASSWORD);
  const history = useHistory();
  const [validations, setValidations] = useState({
    length: false,
    uppercase: false,
    number: false,
    specialChar: false,
    equalPasswords: false,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseResponse = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChangePass = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...passwords, [name]: value };
    const updatedValidations = validatePassword(updatedValues);
    setPasswords(updatedValues);
    setValidations(updatedValidations);
  };

  const validatePassword = (values) => {
    return {
      length: values.pass.length >= 8,
      uppercase: /[A-Z]/.test(values.pass),
      number: /[0-9]/.test(values.pass),
      specialChar: /[!@#$%^&*()_\-+=\[{\]};:'",<.>/?\\|~`]/.test(values.pass),
      equalPasswords:
        values.pass !== "" &&
        values.confirmPassword !== "" &&
        values.pass === values.confirmPass,
    };
  };

  const validationsOptions = [
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM2"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM3"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM4"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM5"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM6"),
  ];

  const colorSpan = (position) => {
    const error = "#EDBABA";
    const success = "#B2E5B7";
    const normal = "#847F7F";
    if (passwords?.pass !== undefined) {
      switch (position) {
        case 0:
          return validations.length ? success : error;
        case 1:
          return validations.uppercase ? success : error;
        case 2:
          return validations.number ? success : error;
        case 3:
          return validations.specialChar ? success : error;
        case 4:
          return validations.equalPasswords ? success : error;
      }
    } else {
      return normal;
    }
  };

  function passwordRequirements(objeto) {
    for (const propiedad in objeto) {
      if (objeto.hasOwnProperty(propiedad) && objeto[propiedad] !== true) {
        return false;
      }
    }
    return true;
  }

  const handleSubmit = async () => {
    const { pass, confirmPass } = passwords;
    const newPassword = await encryptData(pass, password);
    const newPasswordConfirm = await encryptData(confirmPass, password);
    if (passwordRequirements(validations)) {
      try {
        create_Reg_Password({
          variables: {
            token: token,
            password: newPassword,
            confirmPassword: newPasswordConfirm,
          },
        })
          .then((result) => {
            if (
              result?.data?.create_Reg_Password?.message ===
                "Password updated" &&
              result?.data?.create_Reg_Password?.success
            ) {
              history.push("/password_updated");
            } else {
              setResponse("error");
              setOpenResponseAlert(true);
            }
          })
          .catch((err) => {
            if (err) {
              setResponse("error");
              setOpenResponseAlert(true);
            }
          });
      } catch (err) {}
    } else {
      console.error("");
    }
  };

  React.useEffect(() => {
    const { password, new_password } = passwords;
    if (password !== "") setError(false);
    if (new_password !== "") setError2(false);
  }, [passwords]);

  return (
    <div className="resetPassword_container">
      <Snackbar
        open={open && !error && !error2}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert severity="error">
          {t("VIEW_RESET_PASSWORD.PASSWORDS_DONOT_MATCH")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openReponseAlert}
        autoHideDuration={3000}
        onClose={handleCloseResponse}
      >
        <Alert severity={response}>
          {t("VIEW_RESET_PASSWORD.SOMTHING_WENT_WRONG")}
        </Alert>
      </Snackbar>
      <div className="resetPassword_hero">
        <img className="resetPassword_image" src={reset_password} alt="img" />
      </div>
      <div
        style={{
          width: "100%",
          placeItems: "center",
          marginTop: "-20px",
          marginBottom: "10px",
        }}
      >
        {validationsOptions?.map((item, key) => (
          <Validations background={colorSpan(key)}>
            <div style={{ width: "300px" }}>
              <span
                style={{ justifyContent: "space-between", marginRight: "10px" }}
              >
                •
              </span>
              {item}
            </div>
          </Validations>
        ))}
      </div>
      <div className="resetPassword_input_container">
        <div className="resetPassword_input_and_title_container">
          <div className="resetPassword_input_title_container">
            {t("VIEW_RESET_PASSWORD.NEW_PASSWORD_INPUT")}
          </div>
          <input
            className="resetPassword_input"
            type="password"
            placeholder={t("VIEW_RESET_PASSWORD.NEW_PASSWORD_INPUT")}
            name="pass"
            onChange={handleChangePass}
          />
          {error && (
            <div className="resetPassword_span_required_input">
              {t("VIEW_RESET_PASSWORD.REQUIRED_FIELD")}
            </div>
          )}
        </div>
      </div>
      <div className="resetPassword_input_container">
        <div className="resetPassword_input_and_title_container">
          <div className="resetPassword_input_title_container">
            {t("VIEW_RESET_PASSWORD.REPEAT_NEW_PASSWORD")}
          </div>
          <input
            className="resetPassword_input"
            type="password"
            name="confirmPass"
            placeholder={t("VIEW_RESET_PASSWORD.REPEAT_NEW_PASSWORD")}
            onChange={handleChangePass}
          />
          {error2 && (
            <div className="resetPassword_span_required_input">
              {t("VIEW_RESET_PASSWORD.REQUIRED_FIELD")}
            </div>
          )}
        </div>
      </div>
      <div className="resetPassword_submit_container">
        <button className="resetPassword_submit" onClick={handleSubmit}>
          {t("VIEW_RESET_PASSWORD.SAVE")}
        </button>
      </div>
    </div>
  );
}
