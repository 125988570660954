// React hooks
import React from "react";

// components
import ConfirmationIcon from "../../../components/loading/ConfirmationIcon";

//router Dom components
import { Link } from "react-router-dom";

//Multilanguage
import { useTranslation } from "react-i18next";

export default function Confirmation() {
  const { t } = useTranslation();
  return (
    <div className="confirmation_container">
      <div className="confirmation_icon_text">
        <span>
          <ConfirmationIcon src="https://assets7.lottiefiles.com/packages/lf20_5gstlg6x.json" />
        </span>
      </div>
      <div className="confirmation_icon_text size_text">
        <span>
          {t(
            "VIEW_PASSWORD_UPDATED_SUCCESSFULLY.PASSWORD_UPDATED_SUCCESSFULLY"
          )}
        </span>
      </div>
      <div className="confirmation_back_login_container">
        <span className="confirmation_back_login">
          <Link to="/">
            {t("VIEW_PASSWORD_UPDATED_SUCCESSFULLY.RETURN_TO_LOGIN")}
          </Link>
        </span>
      </div>
    </div>
  );
}
