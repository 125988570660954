import { useEffect, useState, useContext } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { AuthContext } from "../../../../context/AuthProvider";

const useSocket = (url, serial) => {
  const { setSocketConection } = useContext(AuthContext);
  const [client, setClient] = useState(null);
  const [dataSocket, setDataSocket] = useState({});

  useEffect(() => {
    const newClient = new W3CWebSocket(url);

    let pingInterval;

    newClient.onopen = () => {
      newClient.send(
        JSON.stringify({ channel: serial, serial, event: "assign_room" })
      );
      setSocketConection(true);

      pingInterval = setInterval(() => {
        if (newClient?.readyState === WebSocket.OPEN) {
          newClient.send(JSON.stringify({ type: 'ping' }));
        }
      }, 30000);
    };

    newClient.onmessage = (e) => {
      try {
        let dataSocket = JSON.parse(e.data);
        if (dataSocket?.type === 'ping') return;
        
        setDataSocket(dataSocket);
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    };

    newClient.onerror = function (error) {
      setSocketConection(false);
    };

    newClient.onclose = () => {
      clearInterval(pingInterval);
      setSocketConection(false);
    };

    setClient(newClient);

    return () => {
      clearInterval(pingInterval);
      newClient.close();
    };
  }, [url, serial]);

  const sendMessage = (message) => {
    if (client && client.readyState === W3CWebSocket.OPEN) {
      client.send(JSON.stringify(message));
    }
  };

  return { client, sendMessage, dataSocket };
};

export default useSocket;
