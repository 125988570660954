import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { GET_PIN_CODES } from "../../../controllers/pinCodesController";

import { AuthContext } from "../../../context/AuthProvider";

import TableListCodes from "./components/TableListCodes";
import ModalDoorLock from "../../modal/ModalDoorLock";

export default function ListCodes() {
  const { id } = useParams();
  const { setCodesGlobal } = useContext(AuthContext);

  const [codes, setCodes] = useState([]);
  const [dataCode, setDataCode] = useState({});

  const [open, setOpen] = useState(false);
  const [view, setView] = useState("add");

  const { data, refetch } = useQuery(GET_PIN_CODES, {
    variables: { DeviceId: parseInt(id) },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (data) {
      const newData = [...data.getPinCodes];
      setCodes(newData.sort((a, b) => a.index - b.index));
      setCodesGlobal(newData);
    }
  }, [data]);

  console.log(data);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleAddRestriction = (data) => {
    setView("restriction");
    setOpen(true);
    setDataCode(data);
  };

  const handleViewHistoryOfSentPasswords = (data) => {
    setView("history");
    setOpen(true);
    setDataCode(data);
  };

  const handleViewSendCodeToPerson = (data) => {
    setView("send");
    setOpen(true);
    setDataCode(data);
  };

  return (
    <>
      <TableListCodes
        listCodes={codes}
        handleOpenModal={handleOpenModal}
        handleAddRestriction={handleAddRestriction}
        handleViewHistoryOfSentPasswords={handleViewHistoryOfSentPasswords}
        handleViewSendCodeToPerson={handleViewSendCodeToPerson}
      />
      {/* El modal siempre esta presente lo que cambia es la visibilidad del mismo */}
      <ModalDoorLock
        title="Crear código"
        open={open}
        handleClose={handleCloseModal}
        view={view}
        setView={setView}
        dataCode={dataCode}
        setDataCode={setDataCode}
        refetchListCode={refetch}
      />
    </>
  );
}
