import {
  useState,
  useEffect,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent
} from "react";
import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";
import {
  GET_SENT_PIN_CODE_REPORT,
  SEND_PIN_CODE_BY_EMAIL
} from "../../../../controllers/pinCodesController";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Snackbar from "@mui/material/Snackbar";

import ReplyIcon from "@mui/icons-material/Reply";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Loading from "../../../loading/Loading";
import { encryptData } from "../../../../utils/encryptDecrypt";

const EMPTY_STRING = "";

const EMAIL_REGEX = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
const FULLNAME_REGEX = /^[A-ZÁÉÍÓÚÑ][a-záéíóúñ]+(\s[A-ZÁÉÍÓÚÑ][a-záéíóúñ]+)*$/;

// Función para generar un historial ficticio de envíos de códigos (HARDCODIADO)
/*
const generateFakeHistory = () => [
  {
    id: 1,
    name: "Errol Guy",
    email: "geccleston4e@phpbb.com",
    idNumber: "358071951786257",
    sentHistory: ["Feb 3, 2025 9:58 AM", "Feb 5, 2025 10:00 AM"] // Fechas de reenvíos
  },
  {
    id: 2,
    name: "Carlos Méndez",
    email: "carlosmendez@gmail.com",
    idNumber: "231456789",
    sentHistory: ["Feb 5, 2025 3:00 PM"]
  },
  {
    id: 3,
    name: "Purcell Cunliffe",
    email: "lkenafaque2o@utexas.edu",
    idNumber: "688300376033386",
    sentHistory: ["Jan 1, 2025 4:41 AM", "Feb 5, 2025 12:30 PM"]
  },
  {
    id: 4,
    name: "Ana Torres",
    email: "anatorres@gmail.com",
    idNumber: "876543210",
    sentHistory: ["Feb 5, 2025 4:15 PM"]
  },
  {
    id: 5,
    name: "Tyson Worviell",
    email: "hcunliffe4v@wikia.com",
    idNumber: "550399365503798",
    sentHistory: ["Jan 16, 2025 4:40 PM"]
  },
  {
    id: 6,
    name: "José García",
    email: "josegarcia@gmail.com",
    idNumber: "98765432",
    sentHistory: ["Feb 5, 2025 5:00 PM"]
  },
  {
    id: 7,
    name: "Hunter Manketell",
    email: "jgimson2@un.org",
    idNumber: "149529828404753",
    sentHistory: ["Jan 10, 2025 1:01 AM"]
  },
  {
    id: 8,
    name: "María Rodríguez",
    email: "mariarodriguez@gmail.com",
    idNumber: "1234567890",
    sentHistory: ["Feb 5, 2025 6:20 PM"]
  },
  {
    id: 9,
    name: "Nero Boshers",
    email: "abalcon5e@state.gov",
    idNumber: "737303290287689",
    sentHistory: ["Jan 31, 2025 9:46 PM"]
  },
  {
    id: 10,
    name: "Luis Sánchez",
    email: "luissanchez@gmail.com",
    idNumber: "345678912",
    sentHistory: ["Feb 6, 2025 8:00 AM"]
  },
  {
    id: 11,
    name: "Sondra Gimson",
    email: "gguerre3f@flickr.com",
    idNumber: "583972704652867",
    sentHistory: ["Jan 12, 2025 5:40 AM"]
  },
  {
    id: 12,
    name: "Isabella López",
    email: "isalopez@gmail.com",
    idNumber: "678912345",
    sentHistory: ["Feb 6, 2025 9:30 AM"]
  },
  {
    id: 13,
    name: "Itch Cutler",
    email: "gwerner4h@so-net.ne.jp",
    idNumber: "878424691361259",
    sentHistory: ["Jan 8, 2025 4:40 PM"]
  },
  {
    id: 14,
    name: "Juan Pérez",
    email: "juanperez@gmail.com",
    idNumber: "456789123",
    sentHistory: ["Feb 6, 2025 10:45 AM"]
  },
  {
    id: 15,
    name: "Gwen Davidi",
    email: "rkerridge14@berkeley.edu",
    idNumber: "816411541367520",
    sentHistory: ["Jan 15, 2025 7:44 PM"]
  },
  {
    id: 16,
    name: "Lucía Martínez",
    email: "luciamartinez@gmail.com",
    idNumber: "765432109",
    sentHistory: ["Feb 6, 2025 12:00 PM"]
  },
  {
    id: 17,
    name: "Scarlett Vlasyuk",
    email: "vhefforde46@fc2.com",
    idNumber: "487379828019225",
    sentHistory: ["Jan 8, 2025 11:14 PM"]
  },
  {
    id: 18,
    name: "Francisco González",
    email: "franciscogonzalez@gmail.com",
    idNumber: "543210987",
    sentHistory: ["Feb 6, 2025 2:30 PM"]
  },
  {
    id: 19,
    name: "Caron Lauks",
    email: "balbinw@comcast.net",
    idNumber: "532711633588012",
    sentHistory: ["Jan 16, 2025 6:15 PM"]
  },
  {
    id: 20,
    name: "Claudia Ramírez",
    email: "claudiaramirez@gmail.com",
    idNumber: "210987654",
    sentHistory: ["Feb 6, 2025 4:00 PM"]
  }
];
const history = generateFakeHistory();
*/

const TRANSLATION_SHORTCUT = "VIEW_AREA.COMPONENT_SEND_CODE_TO_PERSON_MODAL";

const initialFormData = {
  name: EMPTY_STRING,
  email: EMPTY_STRING,
  idNumber: EMPTY_STRING
};

const adapterEmailingsByUser = (sentEmails = []) =>
  // Convertimos el objeto en un array
  Object.values(
    // Transformamos la data para agrupar por usuario
    sentEmails.reduce((acc, item) => {
      const { user, code, date } = item;
      if (!user || !user.idNumber) return acc;
      if (!acc[user.idNumber])
        acc[user.idNumber] = {
          id: user.idNumber,
          name: user.name,
          email: user.email,
          idNumber: user.idNumber,
          sentHistory: [] // Lista de fechas y códigos enviados
        };
      acc[user.idNumber].sentHistory.push({
        code,
        date: new Date(date).toLocaleString() // Convertimos el timestamp a fecha legible
      });
      return acc;
    }, {})
  );

export default function SendCodeToPerson({
  // history,
  dataCode,
  setDataCode,
  setTypeMessage,
  setView,
  handleClose
}) {
  // --- Sección hooks de estados ---

  // manejo de multi-idioma en los labels
  const { t } = useTranslation();

  // datos de los usuarios que se les envió el código
  const [history, setHistory] = useState([]);

  // datos del nuevo usuario al que se le enviará
  const [formData, setFormData] = useState(initialFormData);

  // errores de validación
  const [errors, setErrors] = useState({});

  // query para obtener los envíos de correo de este código
  const { data, loading, refetch } = useQuery(GET_SENT_PIN_CODE_REPORT, {
    variables: { pin: dataCode.pin },
    fetchPolicy: "network-only"
  });

  // mutación para enviar el código por correo
  const [sendEmail] = useMutation(SEND_PIN_CODE_BY_EMAIL);

  useEffect(() => {
    if (data) {
      const sentPinHistory = adapterEmailingsByUser(data.getSentPinCodeReport);
      setHistory(sentPinHistory);
    }
  }, [data]);

  console.log(history);

  // Se genera el historial de códigos enviados (de momento esta HARDCODE mode)

  // --- Sección de manejadores de eventos ---

  /**
   * Handles input change events for form fields.
   * Updates the form data state with the new value and clears any existing errors for the changed field.
   *
   * @param {ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} event - The input change event.
   * @param {HTMLInputElement | HTMLTextAreaElement} event.target - The target input element.
   * @param {string} event.target.fullname - The name of the input field.
   * @param {string} event.target.value - The new value of the input field.
   */
  // Maneja los cambios en los campos del formulario
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: EMPTY_STRING }));
  };
  /**
   * Handles the keydown event for an input field.
   *
   * This function checks if the pressed key is allowed by validating the key code.
   * If the key is not allowed, the default action is prevented.
   *
   * @param {KeyboardEvent<HTMLDivElement>} event - The keydown event object.
   * @param {number} event.which - The key code of the pressed key (for older browsers).
   * @param {number} event.keyCode - The key code of the pressed key.
   */
  // Manejamos la entrada para admitir solo digitos (Número de identidad)
  const handleInputKeyDown = (event) => {
    const keyCode = event.which || event.keyCode; // Usamos event.which para compatibilidad con navegadores antiguos
    // solo se permiten digitos y la tecla backspace para borrar
    if (keyCode !== 8 && (keyCode < 48 || keyCode > 57)) event.preventDefault(); // se previene que se registre la tecla si no está permitida
  };
  /**
   * Handles input focus events for form fields.
   * Updates the form data state with the new value and clears any existing errors for the changed field.
   *
   * @param {FocusEvent<HTMLInputElement | HTMLTextAreaElement>} event - The input change event.
   * @param {HTMLInputElement | HTMLTextAreaElement} event.target - The target input element.
   * @param {string} event.target.fullname - The name of the input field.
   * @param {string} event.target.value - The new value of the input field.
   */
  // Limpiamos los espacios sobrantes al salir de la caja de texto
  const handleInputBlur = (event) => {
    const value = event.target.value.trim();
    if (value !== event.target.value)
      setFormData((prev) => ({ ...prev, [event.target.name]: value }));
  };

  // --- Sección de validaciones ---

  // valida que se especifique nombre y apellido valido
  const getFullNameValidationMessage = () => {
    const fullname = formData.name;
    const errorMessage = fullname
      ? fullname.length < 8
        ? t(`${TRANSLATION_SHORTCUT}.ERRORS.SHORT_NAME`)
        : FULLNAME_REGEX.test(fullname)
        ? EMPTY_STRING
        : t(`${TRANSLATION_SHORTCUT}.ERRORS.VALID_NAME`)
      : t(`${TRANSLATION_SHORTCUT}.ERRORS.MANDATORY_FIELD`);
    return errorMessage;
  };

  // valida que se especifique el correo electronico
  const getEmailValidationMessage = () => {
    const emailAddress = formData.email;
    const errorMessage = emailAddress
      ? EMAIL_REGEX.test(emailAddress)
        ? EMPTY_STRING
        : t(`${TRANSLATION_SHORTCUT}.ERRORS.VALID_EMAIL`)
      : t(`${TRANSLATION_SHORTCUT}.ERRORS.MANDATORY_FIELD`);
    return errorMessage;
  };

  // valida la longitud del número de identificación nacional
  const getIdNumberValidationMessage = () => {
    const nationalIdentificationNumber = formData.idNumber;
    const errorMessage = nationalIdentificationNumber
      ? nationalIdentificationNumber.length < 6
        ? t(`${TRANSLATION_SHORTCUT}.ERRORS.VALID_NIN`)
        : EMPTY_STRING
      : t(`${TRANSLATION_SHORTCUT}.ERRORS.MANDATORY_FIELD`);
    return errorMessage;
  };

  /**
   * Validates the form data and sets errors if any validation fails.
   * @returns {boolean} Returns true if the form is valid, otherwise false.
   *
   * @description
   * This function validates the form data by checking the following:
   * - name: Must be non-empty, at least 8 characters long, and match the FULLNAME_REGEX pattern.
   * - Email: Must be non-empty and match the EMAIL_REGEX pattern.
   * - idNumber: Must be non-empty, at least 6 digits long.
   *
   * If any validation fails, the corresponding error message is set in the `newErrors` object.
   * The errors state is updated with the newErrors object.
   */
  const validateForm = () => {
    const newErrors = {};

    const nameValidationMessage = getFullNameValidationMessage();
    const emailValidationMessage = getEmailValidationMessage();
    const idNumberValidationMessage = getIdNumberValidationMessage();

    if (nameValidationMessage) newErrors.name = nameValidationMessage;
    if (emailValidationMessage) newErrors.email = emailValidationMessage;
    if (idNumberValidationMessage)
      newErrors.idNumber = idNumberValidationMessage;

    setErrors(newErrors);

    return !Object.keys(newErrors).length;
  };

  // Maneja el envío del formulario
  const handleSubmit = async () => {
    console.log(formData);
    if (validateForm()) {
      // AQUI SE DEBE EJECUTAR LA MUTACIÓN QUE ENVIA/REENVIA EL CÓDIGO AL USUARIO
      const isSuccess = await prepareAndSendEmail(dataCode.pin, formData);
      if (isSuccess) {
        setOpen(true);
        refetch();
      }
      console.log(
        "Enviando correo al usuario:",
        formData,
        "con el código de cerradura:",
        dataCode.pin
      );
    }
  };

  const [open, setOpen] = useState(false);

  const prepareAndSendEmail = async (pin, person) => {
    console.log({ variables: { pin, user: person } });
    try {
      const SECRET_KEY = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY ?? "";
      const ENCRYPTED_PIN = await encryptData(dataCode.pin, SECRET_KEY);
      const ENCRYPTED_NUIP = await encryptData(person.idNumber, SECRET_KEY);
      const RECIPIENT_DATA_PREPARED = {
        variables: {
          pin: ENCRYPTED_PIN,
          user: {
            name: person.name,
            email: person.email,
            idNumber: ENCRYPTED_NUIP
          }
        }
      };
      setOpen(true);
      // AQUI SE DEBE EJECUTAR LA MUTACIÓN QUE ENVIA/REENVIA EL CORREO CON EL CÓDIGO
      const emailResponse = await sendEmail(RECIPIENT_DATA_PREPARED);
      console.log(
        emailResponse.data.resendPinCodeByEmail
          ? "Envío exitoso"
          : "Envío fallido"
      );
    } catch (error) {
      console.log("****************************");
      console.log("FALLÓ");
      console.log("****************************");
      console.log(error);
    }
  };

  const handleClickResend = async (selectedUser) => {
    setOpen(true);
    // AQUI SE DEBE EJECUTAR LA MUTACIÓN QUE ENVIA/REENVIA EL CÓDIGO AL USUARIO
    const isSuccess = await prepareAndSendEmail(dataCode.pin, selectedUser);
    if (isSuccess) {
      setOpen(true);
      refetch();
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  if (loading)
    return (
      <Box sx={{ mt: -35 }}>
        <Loading />
      </Box>
    );

  if (!history.length)
    return (
      <Typography variant="h3" sx={{ p: 10 }}>
        {t("VIEW_AREA.COMPONENT_FULL_MODAL_BATTERY.NO_DATA")}
      </Typography>
    );

  return (
    <Paper elevation={3} sx={{ padding: 4, maxWidth: 600, margin: "auto" }}>
      {/* Muestra el código a enviar */}
      <Typography fontWeight={900} variant="h6">
        {t(`${TRANSLATION_SHORTCUT}.LABELS.CODE`)}: {dataCode.pin}
      </Typography>
      {/* Sección del formulario para enviar un nuevo código */}
      <Typography variant="h6" marginTop="1rem" gutterBottom>
        {t(`${TRANSLATION_SHORTCUT}.LABELS.SEND_TO`)}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            size="small"
            label={t(`${TRANSLATION_SHORTCUT}.LABELS.USER_FULLNAME`)}
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={Boolean(errors.name)}
            helperText={errors.name}
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            size="small"
            label={t(`${TRANSLATION_SHORTCUT}.LABELS.USER_EMAIL`)}
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={Boolean(errors.email)}
            helperText={errors.email}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            label={t(`${TRANSLATION_SHORTCUT}.LABELS.USER_NIN`)}
            name="idNumber"
            value={formData.idNumber}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyPress={handleInputKeyDown}
            error={Boolean(errors.idNumber)}
            helperText={errors.idNumber}
            fullWidth
          />
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2} justifyContent="flex-end" mt={3}>
        <Button onClick={handleClose} color="primary" variant="outlined">
          {t(`${TRANSLATION_SHORTCUT}.BUTTONS.CANCEL`)}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t(`${TRANSLATION_SHORTCUT}.BUTTONS.SEND_CODE`)}
        </Button>
      </Stack>
      <Divider sx={{ mt: 3, borderStyle: "dashed" }} />
      {/* Sección del historial de envíos */}
      <Typography variant="h6" mt={2} mb={3}>
        {t(`${TRANSLATION_SHORTCUT}.LABELS.HISTORY_TITLE`)}
      </Typography>
      <Grid container spacing={2} sx={{ maxHeight: 200, overflow: "auto" }}>
        {history.map((user) => (
          <Grid item xs={12} key={user.id}>
            <Paper
              variant="elevation"
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 2,
                gap: 1
              }}
            >
              <Typography variant="h6" fontWeight={600}>
                {user.name}
                <Tooltip
                  title={t(`${TRANSLATION_SHORTCUT}.TOOLTIPS.RESEND_EMAIL`)}
                >
                  <IconButton
                    onClick={() =>
                      handleClickResend({
                        name: user.name,
                        email: user.email,
                        idNumber: user.idNumber
                      })
                    }
                  >
                    <ReplyIcon />
                  </IconButton>
                </Tooltip>
                <Snackbar
                  open={open}
                  autoHideDuration={5000}
                  onClose={handleCloseAlert}
                  message={t(`${TRANSLATION_SHORTCUT}.BUTTONS.RESEND_CODE`)}
                />
              </Typography>
              <Typography variant="body2">
                {t(`${TRANSLATION_SHORTCUT}.LABELS.USER_EMAIL`)}: {user.email}
              </Typography>
              <Typography variant="body2">
                {t(`${TRANSLATION_SHORTCUT}.LABELS.USER_NIN`)}: {user.idNumber}
              </Typography>
              <Typography variant="subtitle2">
                {t(`${TRANSLATION_SHORTCUT}.LABELS.SENT_COUNT`)}:{" "}
                {user.sentHistory.length}
              </Typography>
              <Typography variant="subtitle2">
                {t(`${TRANSLATION_SHORTCUT}.LABELS.VIEW_SENT_DATES`)}:
              </Typography>
              <List dense>
                {user.sentHistory.map((report, index) => (
                  <ListItem key={`user${index}date${index}`}>
                    <ListItemIcon>
                      <CalendarMonthIcon />
                    </ListItemIcon>
                    <ListItemText primary={report.date} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}
