import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { GET_SENT_PIN_CODE_REPORT } from "../../../../controllers/pinCodesController";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Loading from "../../../loading/Loading";

// Función para generar un historial ficticio de envíos de códigos
/*
const generateFakeHistory = () => {
  const nombres = [
    "Carlos",
    "María",
    "José",
    "Ana",
    "Luis",
    "Laura",
    "Pedro",
    "Marta",
    "Javier",
    "Lucía",
    "Manuel",
    "Paula",
    "Andrés",
    "Isabel",
    "Fernando",
    "Sofía",
    "Diego",
    "Elena",
    "Raúl",
    "Patricia"
  ];
  const apellidos = [
    "García",
    "Martínez",
    "López",
    "González",
    "Rodríguez",
    "Pérez",
    "Fernández",
    "Sánchez",
    "Ramírez",
    "Torres",
    "Díaz",
    "Vázquez",
    "Cruz",
    "Ortiz",
    "Morales",
    "Herrera",
    "Ruiz",
    "Jiménez",
    "Mendoza",
    "Ramos"
  ];
  return Array.from({ length: 10 }, () => {
    const name = nombres[Math.floor(Math.random() * nombres.length)];
    const surname = apellidos[Math.floor(Math.random() * apellidos.length)];
    return {
      name: `${name} ${surname}`,
      email: `${name
        .charAt(0)
        .toLowerCase()}${surname.toLowerCase()}@gmail.com`,
      idNumber: Array.from(
        {
          length: Math.floor(Math.random() * 3) + 8
        },
        () => Math.floor(Math.random() * 10)
      ).join(""),
      sentHistory: Array.from(
        { length: Math.floor(Math.random() * 5 + 1) },
        () => new Date().toLocaleDateString()
      )
    };
  });
};
*/

// Se genera el historial de códigos enviados de momento esta HARDCODIADO
// const history = generateFakeHistory();
const TRANSLATION_SHORTCUT = "VIEW_AREA.COMPONENT_SEND_CODE_TO_PERSON_MODAL";

export default function HistoryOfSentPasswords({
  dataCode,
  setTypeMessage,
  setView,
  setDataCode
}) {
  // manejo de multi-idioma en los labels
  const { t } = useTranslation();
  const [history, setHistory] = useState([]);

  const { data, loading, refetch } = useQuery(GET_SENT_PIN_CODE_REPORT, {
    fetchPolicy: "network-only"
  });

  const adapterEmailingsByUser = (sentEmails = []) =>
    // Convertimos el objeto en un array
    Object.values(
      // Transformamos la data para agrupar por usuario
      sentEmails.reduce((acc, item) => {
        const { user, code, date } = item;
        if (!user || !user.idNumber) return acc;
        if (!acc[user.idNumber])
          acc[user.idNumber] = {
            id: user.idNumber,
            name: user.name,
            email: user.email,
            idNumber: user.idNumber,
            sentHistory: [] // Lista de fechas y códigos enviados
          };
        acc[user.idNumber].sentHistory.push({
          code,
          date: new Date(date).toLocaleString() // Convertimos el timestamp a fecha legible
        });
        return acc;
      }, {})
    );

  useEffect(() => {
    if (data) {
      const sentPinHistory = adapterEmailingsByUser(data.getSentPinCodeReport);
      setHistory(sentPinHistory);
    }
  }, [data]);

  console.log(history);

  if (loading)
    return (
      <Box sx={{ mt: -35 }}>
        <Loading />
      </Box>
    );

  if (!history.length)
    return (
      <Typography variant="h3" sx={{ p: 10 }}>
        {t("VIEW_AREA.COMPONENT_FULL_MODAL_BATTERY.NO_DATA")}
      </Typography>
    );

  return (
    <Paper elevation={3} sx={{ padding: 4, maxWidth: 600, margin: "auto" }}>
      {/* Muestra el código a enviar */}
      <Typography fontWeight={900} variant="h6">
        {t(`${TRANSLATION_SHORTCUT}.LABELS.CODE`)}: {dataCode.pin}
      </Typography>
      {/* Sección del historial de envíos */}
      <Typography variant="h6" mt={2} mb={3}>
        {t(`${TRANSLATION_SHORTCUT}.LABELS.HISTORY_TITLE`)}
      </Typography>
      <Grid container spacing={2} sx={{ maxHeight: 200, overflow: "auto" }}>
        {history.map((user) => (
          <Grid item xs={12} key={user.id}>
            <Paper
              variant="elevation"
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 2,
                gap: 1
              }}
            >
              <Typography variant="h6" fontWeight={600}>
                {user.name}
              </Typography>
              <Typography variant="body2">
                {t(`${TRANSLATION_SHORTCUT}.LABELS.USER_EMAIL`)}: {user.email}
              </Typography>
              <Typography variant="body2">
                {t(`${TRANSLATION_SHORTCUT}.LABELS.USER_NIN`)}: {user.idNumber}
              </Typography>
              <Typography variant="subtitle2">
                {t(`${TRANSLATION_SHORTCUT}.LABELS.SENT_COUNT`)}:{" "}
                {user.sentHistory.length}
              </Typography>
              <Typography variant="subtitle2">
                {t(`${TRANSLATION_SHORTCUT}.LABELS.VIEW_SENT_DATES`)}:
              </Typography>
              <List dense>
                {user.sentHistory.map((report, index) => (
                  <ListItem key={`user${index}date${index}`}>
                    <ListItemIcon>
                      <CalendarMonthIcon />
                    </ListItemIcon>
                    <ListItemText primary={report.date} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}
